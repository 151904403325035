$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    width: 100%;
    padding: 0;
    float: left;

    @media only screen and (max-width: 800px) {
        padding: 15px 0 10px 0;
    }

    .container {
        display: block;
        max-width: 720px;
        margin: 0 auto;
        width: 90%;

        @media only screen and (max-width: 640px) {
            width: 100%;
        }
    }
}

:global(.gameModal) {
    display: inline-block;
    background: #fff;
    width: 100%;
    padding: 0;
    margin: 0;

    :global(.ant-modal) {
        max-width: 100%;
        padding: 0;
        margin: 0;
        top: 0;

        width: 100% !important;
        min-height: 100vh;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    :global(.ant-modal-body) {
        padding: 0 5%;
        float: left;
    }

    :global(.ant-modal-content) {
        box-shadow: none;
    }
}

.gameWrapper {
    display: inline-block;
    padding: 55px 0;
    width: 100%;
    float: left;

    @media only screen and (max-width: 1100px) {
        padding: 30px 0;
    }

    .heading {
        display: inline-block;
        text-align: center;
        width: 100%;

        h3 {
            font-family: 'IQOS', 'Helvetica Neue', sans-serif;
            font-weight: bold;
            line-height: 40px;
            font-size: 32px;
            color: #34303d;

            margin: 0 0 15px;

            @media only screen and (max-width: 640px) {
                line-height: 40px;
                font-size: 32px;
            }
        }

        p {
            font-family: 'IQOS', 'Helvetica Neue', sans-serif;
            line-height: 28px;
            font-size: 18px;
            color: #34303d;
            width: 100%;

            display: block;
            max-width: 460px;
            margin: 0 auto;

            b {
                display: block;
            }
        }
    }

    .answer {
        display: inline-block;
        text-align: center;
        margin: 75px 0 50px;
        width: 100%;

        text-transform: uppercase;
        font-family: 'IQOS', 'Helvetica Neue', sans-serif;
        letter-spacing: 12px;
        font-weight: bold;
        line-height: 42px;
        text-indent: 10px;
        font-size: 30px;
        color: #34303d;

        &.error {
            color: #db3624;
            margin: 75px 0 25px;
        }

        @media only screen and (max-width: 640px) {
            letter-spacing: 15px;
            font-size: 26px;
            margin: 35px 0;
        }
    }

    .success {
        margin: 45px auto;
    }

    .errMessage {
        display: flex;
        flex-flow: column;
        width: 100%;
        float: left;
        align-items: center;

        h3 {
            font-family: 'IQOS', 'Helvetica Neue', sans-serif;
            font-weight: bold;
            line-height: 32px;
            font-size: 24px;
            color: #db3624;
            max-width: 450px;
            margin: 0 0 35px;
            text-align: center;
        }

        button {
            margin: 25px auto 35px;
            display: block;
        }
    }

    .successMessage {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: center;

        h2 {
            text-transform: uppercase;
            letter-spacing: 10px;
            font-weight: 700;
            margin: 20px auto;
            text-align: center;
            line-height: 22px;
            font-size: 20px;
        }

        h3 {
            font-family: 'IQOS', 'Helvetica Neue', sans-serif;
            font-weight: bold;
            line-height: 35px;
            font-size: 28px;
            color: #00d1d2;
            text-align: center;
            margin: 0 0 25px;
        }

        p {
            display: block;
            max-width: 420px;
            margin: 0 auto 20px;

            line-height: 24px;
            font-size: 16px;
            color: #34303d;

            
            span{
                font-size: 12px;
                line-height: 16px;
                display: block;
                margin: 0 auto 20px;
            }
        }

        > button {
            margin: 25px auto;
            display: block;
        }

        .benefits {
            display: block;
            background: #00d1d2;
            margin: 40px auto;
            max-width: 520px;
            overflow: hidden;
            padding: 25px;
            width: 100%;

            @media only screen and (max-width: 640px) {
                margin: 20px auto;
                padding: 15px;
            }

            h3 {
                line-height: normal;
                font-weight: bold;
                font-size: 18px;
                color: #34303d;

                text-align: left;
                margin: 0 0 15px;
                width: 100%;
                float: left;
            }

            .item {
                display: inline-block;
                text-align: left;
                width: 100%;
                float: left;

                &:not(:last-child) {
                    margin: 0 0 15px;
                }

                img {
                    display: inline-block;
                    width: 50px;
                    float: left;
                }

                .content {
                    display: inline-block;
                    width: calc(100% - 75px);
                    text-align: left;
                    float: right;

                    p {
                        font-size: 18px;
                        color: #34303d;
                        margin: 0;

                        button {
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .status {
        display: inline-block;
        text-align: center;
        margin: 10px 0 0;
        width: 100%;
    }

    .keyboard {
        transition: all 300ms ease;

        padding: 0 20px;
        margin: 25px -5%;
        width: 110%;
        float: left;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        @media only screen and (max-width: 460px) {
            margin: 25px -5% 0;
            width: 110%;
        }

        @media only screen and (max-width: 360px) {
            br {
                display: none;
            }
        }

        button {
            display: inline-block;
            border: 1px solid #f6f4f0;
            background: #f6f4f0;
            height: 58px;
            width: 52px;

            border-radius: 8px;
            margin: 0 5px 10px;
            cursor: pointer;

            font-family: 'IQOS', 'Helvetica Neue', sans-serif;
            text-transform: uppercase;
            font-size: 14px;
            color: #34303d;

            &:hover {
                border: 1px solid #c1c0c4;
            }

            &:active {
                border: 1px solid #99979d;
                background: #99979d;
            }

            &:disabled {
                border: 1px solid #db3624;
                background: #db3624;
                color: #fffdfb;
            }

            &.correct {
                border: 1px solid #00d1d2;
                background: #00d1d2;
            }

            @media only screen and (max-width: 800px) {
                height: 58px;
                width: 42px;
            }

            @media only screen and (max-width: 640px) {
                height: 58px;
                width: 36px;
            }

            @media only screen and (max-width: 520px) {
                margin: 0 2px 6px;
                padding: 0;

                height: 58px;
                width: 32px;
            }

            @media only screen and (max-width: 390px) {
                width: 29px;
            }

            @media only screen and (max-width: 360px) {
                width: 26px;
            }
        }
    }
}
