$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.reward {
    display: flex;
    background: $white-smoke-color;
    position: relative;

    padding: 4px 10px 4px 4px;
    border-radius: 50px;
    margin: 0 0 24px;

    &.noText {
        padding: 4px;

        span {
            display: none;
        }
    }

    &.absolute {
        position: absolute;

        left: 25px;
        top: 25px;
    }

    &.absoluteForSlider {
        position: absolute;

        left: 25px;
        top: 50px;
    }

    &.small {
        transform: scale(0.7);
        left: -10px;
        top: 5px;
        min-width: auto;

        @media only screen and (min-width: 1400px) {
            transform: scale(0.9);
            top: 5px;
            left: 0;
        }

        span {
            margin: 2px 0 0 2px;
        }
    }

    &.smaller {
        margin: 0;

        svg {
            width: 21px;
            height: 21px;
        }

        span {
            margin: 3px 0 0 4px;
            line-height: 14px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    svg {
        height: 34px;
        width: 34px;
        float: left;
    }

    span {
        display: inline-block;
        line-height: 34px;
        font-size: 14px;

        margin: 0 0 0 4px;

        b {
            font-size: 14px;
        }
    }

    &.points {
        span {
            margin: 3px 0 0 5px;
            line-height: 14px;
            font-size: 12px;
        }
    }
}
