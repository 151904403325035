$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
    width: 100%;
}

.clue {
    width: 100%;
    text-align: center;

    p {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        color: $slate-color;
        margin: 0;

        &:first-of-type {
            margin-bottom: 5px;
        }
    }
}

.matrix {
    width: 100%;
    aspect-ratio: 1;
    margin-bottom: 25px;
    background-size: cover;

    .row {
        display: flex;

        .column {
            transition: all 300ms ease;
            // aspect-ratio: 1; // removed aspect ratio from the column as it doesn't have any effect on unequal matrices and seems to fuck up safari 
            background-color: rgba($dark-title, 0.9);
            backdrop-filter: blur(6px);
        }

        .guessed {
            background-color: transparent;
            backdrop-filter: none;
        }
    }
}

.guessedWord {
    display: inline-block;
    text-align: center;
    margin: 25px 0 25px;
    width: 100%;

    text-transform: uppercase;
    letter-spacing: 6px;
    font-weight: 700;
    text-indent: 10px;
    font-size: 17px;
    color: $slate-color;
}

.wrongLetters {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 1px solid $accent-yellow;
    background-color: rgba(255, 214, 87, 0.25);
    margin-bottom: 24px;

    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    color: $slate-color;
}

.keyboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;


    .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;

        button {
            transition: all 300ms ease;
            border: 1px solid $disabled-color;
            background: $disabled-color;
            width: 100%;
            max-width: 43px;
            min-width: 28px;
            height: 43px;
            border-radius: 4px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 21px;
            color: $slate-color;
            padding: 0;

            &:hover {
                border: 1px solid #c1c0c4;
            }

            &:active {
                border: 1px solid #99979d;
                background: #99979d;
            }

            &:disabled {
                border: 1px solid #db3624;
                background: #db3624;
                color: #fffdfb;
            }

            &.correct {
                border: 1px solid #00d1d2;
                background: #00d1d2;
            }
        }

        &:nth-child(2) {
            padding: 0 24px;
        }

        &:nth-child(3) {
            padding: 0 72px;
        }

    }

    &.disabled {
        pointer-events: none;
    }

    @media only screen and (max-width: 374px) {
        gap: 5px;

        .row {
            gap: 5px;

            button {
                min-width: 25px;
                font-size: 19px;
            }
        }

    }
}