$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    width: 100%;
    .mobileHeight {
        img {
            height: auto !important;
        }
    }

    .badges {
        background-color: $turquoise-color;
        padding: 0 0 20px 0;
        margin-bottom: 16px;

        @media only screen and (max-width: 750px) {
            width: 112%;
            margin: 0 0 0 -5.5%;
        }

        @media only screen and (max-width: 400px) {
            margin-top: 16px;
        }

        .descriptionContainer {
            padding: 36px 32px 0;

            .badgesTitle {
                font-weight: 700;
                font-size: 24px;
                text-align: left;
                line-height: 29px;
            }

            .badgesSubtitle {
                line-height: 20px;
                font-size: 16px;
                text-align: left;
                margin-bottom: 32px;
            }
        }

        .card {
            display: flex;
            align-items: center;
            flex-direction: column;

            background-color: $soft-white;
            padding: 44px 24px 24px;
            border-radius: 10px;
            max-height: 373px;
            min-width: 258px;

            &:first-child {
                margin-left: 32px;
            }

            .cardImage {
                margin-bottom: 16px;
                height: 140px;
                width: 140px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .title {
                line-height: 25px;
                font-weight: 700;
                font-size: 16px;

                margin-bottom: 16px;
                text-align: center;
            }
        }

        .emptyCard {
            min-width: 40px;
        }
    }
}
