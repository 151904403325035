$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    .content {
        width: 100%;
        max-width: 100%;

        p {
            display: inline-block;
            text-align: left;
            background-color: $turquoise-color;
            width: 100%;
            max-width: 100%;
            padding: 46px 24.5px;
            margin: 0;

            @media only screen and (max-width: 849px) {
                width: 100vw;
                max-width: 100vw;
                margin: 0 -6vw 16px -6vw;
            }
        }
    }

    .completed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 36px 24px;
        background-color: $white-smoke-color;

        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            text-align: left;
            color: $slate-color;
            margin: 0 0 16px 0;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: $slate-color;
            margin: 0 0 24px 0;
        }

        img {
            width: 100%;
            max-width: 330px;
        }
    }
}
