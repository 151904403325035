$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;

    @media only screen and (max-width: 640px) {
        gap: 24px;
        margin-bottom: 0;

        .sliderTitle {
            font-size: 21px !important;
            line-height: 25px !important;
        }
    }

    .sliderTitle {
        text-align: center;
        font-size: 28px;
        line-height: 32px;
    }

    .customHero {
        height: 100% !important;

        div:first-of-type {
            justify-content: center;

            @media only screen and (max-width: 420px) {
                justify-content: flex-start;
            }
        }

        .heroDetails {
            margin-bottom: 0;

            h3 {
                margin-top: 12px !important;
                margin-bottom: 16px !important;

                h4 {
                    font-size: 18px;
                    margin: 16px 0 0;
                }
            }

            p {
                margin-bottom: 16px !important;
            }

            img {
                max-width: 120px;
                margin-bottom: 16px;
            }
        }

        .videoContainer {
            width: 65% !important;

            div {
                aspect-ratio: 16/9 !important;
            }

            img {
                aspect-ratio: 16/9;
                object-position: top !important;
            }
        }

        .details {
            width: 50% !important;
        }

        @media only screen and (max-width: 1290px) {
            flex-direction: column-reverse;

            .videoContainer {
                width: 100% !important;
                height: 100% !important;
            }

            .details {
                width: 100% !important;
                height: 100% !important;
                padding: 24px !important;
            }
        }
    }

    .customHeroEnded {
        height: 100% !important;

        div:first-of-type {
            justify-content: center;

            @media only screen and (max-width: 420px) {
                justify-content: flex-start;
            }
        }

        .heroDetails {
            margin-bottom: 0;

            h3 {
                //color: $soft-white !important;
                margin-top: 12px !important;
                margin-bottom: 16px !important;

                h4 {
                    font-size: 18px;
                    margin: 16px 0 0;
                }
            }

            p {
                margin-bottom: 16px !important;
            }

            img {
                max-width: 120px;
                margin-bottom: 16px;
            }
        }

        .videoContainer {
            width: 65% !important;

            div {
                aspect-ratio: 16/9 !important;
            }

            img {
                aspect-ratio: 16/9;
                object-position: top !important;
            }
        }

        .details {
            width: 50% !important;
        }

        @media only screen and (max-width: 1290px) {
            flex-direction: column-reverse;

            .videoContainer {
                width: 100% !important;
                height: 100% !important;
            }

            .details {
                width: 100% !important;
                height: 100% !important;
                padding: 24px !important;
                justify-content: flex-start !important;
                min-height: auto !important;
            }
        }

        @media only screen and (max-width: 850px) {
            .details {
                .heroDetails {
                    h3 {
                        font-size: 20px !important;
                        line-height: 22px !important;
                        margin-top: 12px;
                    }

                    img {
                        max-width: 85px;
                        margin: 0;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }
    }

    .section {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        background-color: $slate-color;
        margin: 0;

        @media only screen and (max-width: 870px) {
            border-radius: 15px;
        }

        &.reverse {
            flex-direction: row-reverse;
        }

        &.minFullWidth {
            min-width: 100%;
        }

        &.turquoise {
            min-width: 100%;
            background-color: $turquoise-color;

            .itemPadded {
                background-color: $turquoise-color;
                position: relative;

                .labelDateWeekly {
                    position: absolute;
                    top: 32px;
                    left: 32px;
                }

                img {
                    border-radius: 10px;
                }
            }

            h2,
            h3,
            h4,
            p {
                color: $slate-color !important;
                text-align: left;
            }
        }

        .disclaimer {
            border-radius: 5px;
            border: 1px solid $turquoise-color;
            background: rgba(0, 209, 210, 0.5);
            padding: 8px 10px;

            .disclaimerText {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color: $soft-white;
                text-align: left !important;
            }
        }

        .mobileBadgeContainer {
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            gap: 8px;
            margin: 24px 0 0;

            transform: scale(0.8);

            .rewardBadge {
                z-index: 0;
                margin: 0;
            }

            .badgeIcon {
                display: flex;
                align-items: center;
                justify-content: center;

                background: $soft-white;
                border-radius: 50px;
                padding: 3px;

                height: 42px;
                width: 42px;

                svg {
                    width: 100%;
                    float: left;
                }
            }
        }

        .left,
        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            img {
                width: 100%;
            }
        }

        .left {
            width: 45%;
            padding: 32px;
            gap: 32px;

            .title {
                display: flex;
                flex-direction: column;
                gap: 16px;

                h2 {
                    width: 100%;
                    color: $soft-white;
                    font-size: 29px;
                    line-height: 32px;
                    margin: 0;
                }

                h3 {
                    width: 100%;
                    color: $soft-white;
                    font-size: 21px;
                    line-height: 25px;
                    margin: 0;
                    text-align: left;
                }

                h4 {
                    width: 100%;
                    color: $soft-white;
                    font-size: 18px;
                    line-height: 21px;
                    margin: 0;
                }
            }

            p {
                width: 100%;
                color: $soft-white;
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }

            img {
                width: 100%;
                max-width: 157px;
            }

            .text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;

                .weeklyPrizesDateLabel {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 24px;
                }

                .rewardBadge {
                    margin: 0;

                    span {
                        text-align: left;
                    }
                }

                .badgeContainer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    flex-wrap: wrap;

                    .rewardBadge {
                        z-index: 0;
                    }

                    .badgeIcon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        background: $soft-white;
                        border-radius: 50px;
                        padding: 3px;

                        height: 42px;
                        width: 42px;

                        svg {
                            width: 100%;
                            float: left;
                        }
                    }
                }
            }

            @media only screen and (max-width: 640px) {
                gap: 8px;
            }
        }

        .right {
            display: block;
            width: 55%;
        }

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 100%;

            .vimeo {
                width: 100%;
                aspect-ratio: 1;
            }
        }

        .itemPadded {
            padding: 24px;
            background-color: $slate-color;

            position: relative;

            .dateBadgeBigPrize {
                position: absolute;
                top: 32px;
                left: 32px;
            }

            img {
                border-radius: 10px;
            }
        }

        .video {
            width: 100%;
        }

        .vimeo {
            width: 100%;
            aspect-ratio: 1;
        }

        @media only screen and (max-width: 1024px) {
            flex-direction: column-reverse;

            .section {
                position: relative;
            }
            &.reverse {
                flex-direction: column-reverse;

                .bigMikonosPrize {
                    order: 1;

                    width: 100%;
                    color: $soft-white;
                    font-size: 29px;
                    line-height: 32px;
                    margin: 24px 0 0 0;
                    text-align: center;
                }
            }

            .left,
            .right {
                width: 100%;

                .text {
                    align-items: center;
                    text-align: center;

                    .title {
                        h2 {
                            text-align: center !important;
                        }

                        h3 {
                            text-align: center !important;
                        }
                    }

                    p {
                        text-align: center;
                    }

                    .badgeContainer {
                        position: absolute;
                        top: 87px;
                        left: 30px;
                        z-index: 998;
                        max-width: 82%;

                        @media only screen and (max-width: 400px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .rewardBadge {
                            span {
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .left {
                padding: 24px;
            }

            .right {
                background-color: $soft-white;
                position: relative;
            }

            .itemPadded {
                padding: 24px 24px 0;
            }
        }

        @media only screen and (max-width: 640px) {
            &.turquoise,
            &.minFullWidth {
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;
                margin-left: 0;
            }
        }
    }

    .carousel {
        @media only screen and (max-width: 640px) {
            width: 100vw;
            margin-left: -6vw;

            .section {
                min-width: 90%;
                border-radius: 20px;
                padding: 0;
            }

            :global(.keen-arrows) {
                width: 100vw;
                margin-left: -5vw;
            }
        }
    }
}
