$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  display: inline-block;
  position: relative;
  margin: 0 0 24px;
  width: 100%;
  float: left;

  .title {
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
    margin: 0 0 16px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0 0 !important;
  }

  &.cover {
    @media only screen and (max-width: 750px) {
      width: 111%;
      margin: 0 0 0 -5.5%;
    }

    img {
      object-fit: cover !important;
      width: 100% !important;
    }
  }

  &.pointer {
    cursor: pointer;
  }

  &.fullHeight {
    img {
      max-height: 100% !important;
    }

  }

  &.noMargin {
    margin: 0 !important;
  }

  img {
    max-height: 375px;
    object-fit: cover;
    width: 100%;
    display: block;
    margin: auto;

    @media only screen and (max-width: 750px) {
      object-fit: contain;

    }


  }

  .playButton {
    transition: 300ms all ease-in;
    position: absolute;
    cursor: pointer;

    left: calc((100% - 56px) / 2);
    top: calc((100% - 56px) / 2);

    &:hover {
      transform: scale(1.1);
    }
  }
}
