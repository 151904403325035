$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {

  .sliderWrapper {
      margin: 0;
  }
  .imageWrapper {
      @media only screen and (max-width: 600px) {
          width: 111%;
          margin: 0 0 0 -5.5%;
      }
      img {
          max-width: 100%;
      }
  }

  .sliderSection {
    background-color: $slate-color;

    @media only screen and (max-width: 600px) {
      width: 111%;
      margin: 0 0 0 -5.5%;
    }

    .slider {
      width: 100%;
      margin: 24px 0 0;

      :global(.keen-arrows) {
        @media only screen and (max-width: 769px) {
          padding: 0 20px 24px 0;
        }
      }

      .slide {
          position: relative;
          display: inline-flex;
          flex-direction: column;
          min-width: 100%;
          width: 100%;
          max-width: 100%;

          img {
              object-fit: cover;
          }
      }
    }

    h3, p {
      color: $soft-white;
      @media only screen and (max-width: 769px) {
        padding: 0 20px;
      }
    }
  }
}
