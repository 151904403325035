$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.advicesPill {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;

  border-radius: 16px;
  border: 1px solid $soft-white;
  background: rgba(255, 253, 251, 0.75);
  max-width: 400px !important;
  width: 90%;
  padding: 14px;
  gap: 8px;

  .icon {
    width: 35px;
    height: 35px;
  }

  .advice {;

    p {
      text-align: left;
      color: $slate-color;
      font-size: 12px !important;
      font-weight: 400;
      line-height: 16px !important;
      margin: 0;

      strong {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        color: $slate-color;
        margin: 0;
      }
    }
  }
}