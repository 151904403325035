$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.dateBadgeContainer {
    width: 100%;

    &.disabled {
        .dateBadge {
            background-color: #E4E4E4!important;
            color: $slate-tint;

            svg {
                path:nth-child(2) {
                    fill: $slate-tint;
                }

                path:nth-child(3) {
                    fill: #8c8a90;
                }
            }
        }
    }

    &.notFullWidth {
        width: fit-content;
    }

    .dateBadge {
        background-color: $soft-white;
        margin-right: 10px;
        max-width: 130px;
        margin-bottom: 0;
        justify-content: flex-start !important;

        svg {
            width: 21px;
            height: 21px;
        }

        &.longText {
            max-width: fit-content;
        }

        span {
            margin: 3px 0 0 4px;
            line-height: 14px;
            font-size: 12px;
            font-weight: bold;
        }
    }
}
