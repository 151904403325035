$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  width: 100%;

  .countdown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 1px solid $accent-yellow;
    background-color: rgba(255, 214, 87, 0.25);
    margin-bottom: 24px;

    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    color: $slate-color;

    span {
      width: 36px;
      text-align: center;
    }
  }

  .gameBoardWrapper {
    //background-color: $white-smoke-color;
    //padding: 18px;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }
  }

  .gameBar {
    margin: 24px 0;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    .scoreGot, .scoreRemaining {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #19CCBA;
      padding: 16px;
      border-radius: 10px;

      @media only screen and (max-width: 500px) {
        span {
          display: block !important;
        }

      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: $slate-color;

        span {
          display: inline;
          color: $soft-white;
        }
      }
    }

    .scoreRemaining {
      background-color: transparent;
      border: 2px solid $slate-color;

      p {
        color: $slate-color;

        span {
          color: #19CCBA;
        }
      }
    }
  }
}