$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    margin-bottom: 32px;

    .topParagraph {
        margin: 0 0 16px 0;
        padding: 0;

        @media only screen and (min-width: 850px) {
            max-width: 100%;
            width: 100%;
            margin: 0 0 24px 0;
        }

        p {
            margin: 0 -5vw 0 -5vw;
            background-color: $white-smoke-color;
            padding: 24px 6vw;

            @media only screen and (min-width: 850px) {
                padding: 36px 52px;
                margin: 0;
            }
        }
    }

    .cardContainer {
        background-color: #19ccba;
        border-radius: 8px;
        padding: 32px 8px 32px 8px;

        @media only screen and (min-width: 850px) {
            padding: 62px 16px 32px 16px;
            width: 90%;
            margin: 0 auto;
        }

        .presentationCards {
            display: flex;
            justify-content: center;
            gap: 8px;

            @media only screen and (min-width: 850px) {
                gap: 36px;
            }

            .cardWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;

                @media only screen and (min-width: 850px) {
                    gap: 52px;
                }

                .image {
                    position: relative;

                    :first-child {
                        opacity: 80%;
                    }

                    .flower {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;

                        @media only screen and (min-width: 850px) {
                            width: 165%;
                            max-width: 227px;
                            height: 165%;
                            max-height: 225px;
                        }
                    }

                    .terea {
                        width: 100%;
                        height: 100%;
                        max-width: 124px;
                        max-height: 110px;
                        z-index: 2;
                        position: relative;

                        @media only screen and (min-width: 850px) {
                            max-width: 207px;
                            max-height: 205px;
                            z-index: 2;
                            position: relative;
                        }
                    }
                }

                .card {
                    background-color: $soft-white;
                    border-radius: 12px;
                    padding: 16px;
                    max-width: 200px;

                    h3 {
                        word-spacing: 100vw;
                        font-size: 20px;
                        line-height: 22px;
                        margin-bottom: 12px;
                    }

                    img {
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0;
                    }
                }
            }
        }

        .description {
            margin: 24px 0;
            font-size: 16px;
            line-height: 20px;

            @media only screen and (min-width: 850px) {
                max-width: 463px;
                margin: 16px auto 32px auto;
            }
        }
    }
}
