$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  display: inline-block;
  width: 100%;
  float: left;

  &.fullWidth {
    width: 111% !important;
    margin: 0 0 0 -5.5%;
  }

  .slide {
    display: flex !important;
    height: 494px;
    width: 100%;

    @media only screen and (max-width: 960px) {
      height: auto;
    }

    @media only screen and (max-width: 850px) {
      flex-direction: column-reverse;
      height: auto;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      background: $slate-color;
      padding: 75px 25px 35px;
      width: 45%;
      float: left;

      &.single {
        padding: 35px 25px;
      }

      @media only screen and (max-width: 850px) {
        padding: 75px 25px 35px 5%;
      }

      @media only screen and (max-width: 850px) {
        min-height: 255px;
        width: 100%;

        &:not(.single) {
          padding: 45px 25px 35px;
        }
      }

      @media only screen and (max-width: 420px) {
        min-height: 275px;
      }

      .details {
        display: inline-block;
        width: 100%;
        float: left;

        h3 {
          color: $soft-white;
          font-size: 24px;
          line-height: 29px;
          font-weight: bold;

          text-align: left;
          margin: 0 0 30px;
          width: 100%;
          float: left;

          h5 {
            font-size: 18px;
            font-weight: bold;
            line-height: 21px;
          }

          img {
            max-width: 150px;
          }

          span {
            color: $turquoise-color;
          }
        }

        p {
          color: $soft-white;
          line-height: 20px;
          font-size: 16px;

          width: 100%;
          float: left;
          margin: 0 0 25px;

          h3 {
            color: $soft-white !important;
            margin-bottom: 0;
            font-size: 21px;
            line-height: 24px;
          }

          b,
          strong {
            font-weight: bold;
          }

          &.disclaimer {
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
          }
        }
      }

      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        margin-bottom: 16px;

        p {
          font-size: 14px;
          line-height: 16px;
          color: $white-smoke-color !important;
          font-weight: 700;
          margin: 0;
        }
      }
    }

    .contentStyleChange {
      padding: 146px 25px 35px;
    }

    .visual {
      display: inline-block;
      position: relative;
      float: right;
      width: 55%;
      padding: 32px;

      &.fullWidthVisual {
        padding: 0 !important;
      }

      @media only screen and (max-width: 850px) {
        height: 280px;
        width: 100%;
        padding: 24px;
      }

      img {
        object-position: center;
        object-fit: cover;
        background: #ddd;

        height: 100%;
        width: 100%;
        float: left;
      }

      video {
        object-position: center;
        object-fit: cover;
        background: #ddd;

        height: 100%;
        width: 100%;
        float: left;
      }

      .play {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &:hover {
          cursor: pointer;
        }
      }

      .logo {
        position: absolute;
        bottom: 40px;
        left: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        z-index: 1001;
        aspect-ratio: 0 !important;

        p {
          font-size: 14px;
          line-height: 16px;
          color: $white-smoke-color !important;
          font-weight: 700;
          margin: 0;
        }
      }

      .vimeoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 100%;
        position: relative;

        .vimeo {
          width: 100%;
          aspect-ratio: 1;
        }
      }
    }

    // Text modes
    &.dark {
      .content {
        h3,
        p {
          color: $slate-color;
        }
      }
    }
  }

  &.columnReverse {
    .slide {
      @media only screen and (max-width: 570px) {
        flex-direction: column;
      }
    }

    :global(.slick-dots) {
      &:global(.slick-dots-bottom) {
        top: 25px !important;
      }
    }
  }

  // Carousel overwrites
  :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    left: 25px;
    top: 35px;

    &:global(.disabled) {
      cursor: default;
      opacity: 0.5;
    }

    &:global(.slick-prev) {
      transform: rotate(-180deg);
      left: 30px;
      top: 39px;

      @media only screen and (max-width: 850px) {
        left: 6%;
      }
    }

    &:global(.slick-next) {
      right: auto;
      left: 75px;
    }
  }

  :global(.slick-dots) {
    &:global(.slick-dots-bottom) {
      bottom: auto;
      left: 25px;
      top: 300px;

      justify-content: flex-start;
      margin: 0;
    }
  }
}
