$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.winnersSection {
  width: 100%;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 850px) {
    margin: 0;
    padding: 0;
  }

  :global(.ant-select) {
    width: 40%;
    background: $white-smoke-color;
    max-width: 350px;
    margin: 0 auto 20px;

    :global(.ant-select-selection-placeholder) {
      font-size: 16px;
    }

    @media only screen and (max-width: 850px) {
      width: 100%;

      :global(.ant-select-selection-item) {
        font-size: 13px;
      }

      :global(.ant-select-selection-placeholder) {
        font-size: 13px;
      }
    }
  }

  :global(.ant-select-selector) {
    padding-left: 16px !important;
    border: none !important;
  }

  :global(.ant-select-arrow) {
    right: 16px !important;
    color: $slate-color;
  }

  :global(.ant-select-clear) {
    top: 48%;
    font-size: 16px;
    width: 16px !important;
    background: transparent;
    right: 34px;
    opacity: 1 !important;
  }

  .details {
    max-width: 750px;
    text-align: center;

    @media only screen and (max-width: 870px) {
      padding: 0;
    }

    a {
      text-decoration: none;
      border-bottom: 2px solid $turquoise-color;
      color: $slate-color;
    }

    h3 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
}

.winners {
  display: inline-block;
  width: 100%;
  background-color: $white-smoke-color;
  padding: 24px 0;
  margin: 0 auto 32px auto;

  @media only screen and (max-width: 850px) {
    width: 100vw;
    margin: 0;

    &.winnersNoWinners {
      margin: 0 auto 32px auto;
      padding: 25px 16px 15px 16px;
    }
  }

  :global(.keen-arrows) {
    padding: 0 25px 20px 0;

    @media only screen and (max-width: 850px) {
      padding: 0 25px 20px 0;
    }
  }

  .sliderWrapper {
    width: 100%;
    padding-left: 12px;
  }

  .noWinners {
    width: 100%;
    text-align: center;
    padding: 25px;
  }

  .item {
    box-sizing: content-box;
    display: inline-block;
    background: $soft-white;
    padding: 8px;

    min-width: 310px;
    max-width: 310px;
    width: 310px;
    height: 122px;

    @media only screen and (max-width: 850px) {
      box-sizing: content-box;
    }

    .thumbnail {
      display: inline-flex;
      align-items: center;
      position: relative;
      height: 122px;
      //width: 56px; // 9:16 asepct ratio (old version)
      width: 135px;
      float: left;

      img {
        display: inline-block;

        height: 122px;
        width: 100%;
        float: left;
      }
    }

    .details {
      display: inline-flex;
      width: calc(100% - 140px); // -60 for old aspect ratio
      height: 100%;
      float: right;
      text-align: left;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 8px;

      @media only screen and (max-width: 850px) {
        width: calc(100% - 140px); // -56 for old aspect ratio
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }

      .prizeDetails {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $slate-color;
      }

      p {
        color: $slate-color;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
      }
    }
  }
}
