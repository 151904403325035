$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.collapse {
  :global(.ant-collapse-header) {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding: 0 !important;
  }

  :global(.ant-collapse-expand-icon) {
    background: #f6f4f0 !important;
    border-radius: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 26px !important;
    height: 26px !important;
    margin-left: -26px !important;
    transform: rotate(90deg);
    margin-top: 3px !important;

    @media only screen and (max-width: 740px) {
      margin-left: 10px !important;
    }
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }

  :global(.ant-collapse-item-active) {
    :global(.ant-collapse-expand-icon) {
      transform: rotate(180deg);
    }
  }
}

.progressContainer {
  position: relative;
  height: 100%;
  width: 100%;
  float: left;
  margin: 32px 0 0 0;

  .progressBar {
    width: 100%;
    display: flex;
    gap: 24px;
    margin: 10px 0 0 0;

    .progressItem {
      background-color: $white-smoke-color;
      height: 8px;
      border-radius: 4px;
      width: 100%;
      position: relative;

      &.active {
        background-color: $turquoise-color;
      }

      &.inactive {
        background-color: $medium-dark-grey;
      }

      &.notActive {
        .tooltipContainer {
          opacity: 0.5;
        }
      }

      .tooltipContainer {
        background-color: $white-smoke-color;
        border-radius: 4px;
        position: absolute;
        padding: 6px 8px;
        top: -42px;
        left: 0;
        right: 0;
        margin: auto;

        height: 26px;
        width: 66px;

        @media only screen and (max-width: 450px) {
          width: auto;
          min-width: 55px;

          .tooltip {
            font-size: 10px !important;
            line-height: 14px !important;
          }
        }

        .active {
          background-color: $white-smoke-color;
        }

        .tooltip {
          line-height: 16px;
          font-weight: 700;
          font-size: 12px;

          color: $slate-color;
          text-align: center;
        }

        .point {
          position: absolute;
          margin-right: auto;
          margin-left: auto;

          bottom: -10px;
          right: 0;
          left: 0;

          height: 0;
          width: 0;

          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-top: 10px solid $white-smoke-color;
        }
      }

      @media only screen and (max-width: 640px) {
        height: 6px;
      }
    }

    @media only screen and (max-width: 640px) {
      gap: 6px;
    }
  }
}

.dreamsSlider {
  padding: 24px 0 0 0;
  margin: 0;
  display: inline-block;
  width: 100%;
  position: relative;

  :global(.keen-arrows) {
    position: absolute;
    top: 0;
    right: 0;
  }

  :has(:global(.keen-slider)) {
    background-color: transparent;
    padding: 0;
  }

  @media only screen and (max-width: 850px) {
    padding: 0;
    width: 112%;
    margin: 0 0 0 -5.5%;

    :has(:global(.keen-slider)) {
      margin: 0 !important;
      background-color: transparent;
      padding: 24px 24px 0;
    }

    :global(.keen-arrows) {
      position: static;
      padding-right: 24px;
    }
  }

  .dreamsSliderItem {
    border-radius: 24px;
    padding: 24px 16px;
    background-color: $white-smoke-color;
    min-width: 328px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 850px) {
      width: 100%;
      min-width: 310px;
    }

    .customDisclaimer {
      padding: 10px 5px 20px;
      margin-bottom: 16px;
    }

    &.notPlayed {
      background-color: $white-smoke-color !important;

      .details {

        &.light {
          .title {
            color: $slate-tint !important;
          }

          .endedCampaignCardTitle {
            h3 {
              color: $slate-tint !important;
            }
          }


          p {
            color: $white-smoke-color
          }
        }

        .title {
          h3 {
            color: $slate-tint !important;
          }
        }

        .endedCampaignCardTitle {
          h3 {
            color: $slate-tint !important;
          }
        }

        p {
          color: inherit;
        }
      }
    }

    &.notDisabled {
      background-color: $slate-color;

      .details {

        &.light {
          .title {
            color: $white-smoke-color
          }

          .endedCampaignCardTitle {
            h3 {
              color: $slate-tint !important;
            }
          }

          p {
            color: $white-smoke-color
          }
        }

        .title {
          h3 {
            color: inherit;
          }
        }

        .endedCampaignCardTitle {
          h3 {
            color: $slate-tint !important;
          }
        }

        p {
          color: inherit;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        display: inline-flex;
        align-items: center;
        gap: 5px;

        .inactiveIcon {
          svg {
            g {
              stroke: $accent-deep-red;
            }
          }
        }

        svg {
          min-width: 20px;
        }

        h3 {
          color: $slate-color;
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
        }
      }

      .endedCampaignCardTitle {
        h3 {
          color: $slate-tint !important;
        }
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: $slate-tint;
      }
    }

    .imageContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      img {
        max-width: 100%;
      }

      .vimeoContainer {
        position: relative;

        .blurVideo {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 5;
          backdrop-filter: blur(3px);
        }

        .vimeo {
          clip-path: inset(2px 2px);
        }
      }

      button,
      a {
        width: fit-content;

        &:disabled {
          box-shadow: inset 0 0 0 2px #e4e4e4 !important;
          background: $disabled-text-color !important;
          color: $slate-tint !important;
        }
      }

      .expiredCta {
        margin: auto;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: $turquoise-color;
      }
    }
  }
}
