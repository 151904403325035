$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

:global(.ant-modal-root) {
    :global(.ant-modal-mask) {
        background: rgba(0, 0, 0, 0.3);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }

    :global(.ant-modal-wrap) {
        margin: auto 10px;

        :global(.ant-modal) {
            width: 100% !important;
            max-width: 360px;

            &.small {
                max-width: 420px;
            }

            &.normal {
                max-width: 460px !important;
            }

            &.large {
                max-width: 640px !important;
            }

            &.full {
                max-width: 100% !important;
            }

            :global(.ant-modal-content) {
                border-radius: 4px;
                padding: 0 !important;

                :global(.ant-modal-body) {
                    padding: 36px 24px 36px 24px;
                    width: 100%;

                    @media only screen and (max-width: 640px) {
                        padding: 24px;
                    }

                    :global(iframe) {
                        margin: 15px 0;
                        width: 100%;
                    }
                }

                :global(.ant-modal-close) {
                    &:hover {
                        background: none !important;
                    }

                    :global(.ant-modal-close-x) {
                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 640px) {
        :global(.ant-modal-wrap) {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;

            :global(.ant-modal) {
                width: 100%;
                height: 100%;
                margin: 0;
                max-width: 100% !important;

                &.autoHeightMobile {
                    :global(.ant-modal-content) {
                        height: auto !important;
                    }
                }
            }

            :global(.ant-modal-content) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100vh !important;
            }
        }
    }
}
