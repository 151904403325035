$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    :global(.content) {
        //margin-bottom: 0;
    }
    .sliderWrapper {
        margin: 0;
    }
    .imageWrapper {
        @media only screen and (max-width: 600px) {
            width: 111%;
            margin: 0 0 0 -5.5%;
        }
        img {
            max-width: 100%;
        }
    }

    .slider {
        width: 100%;
        margin-bottom: 24px;

        @media only screen and (max-width: 600px) {
            width: 111%;
            margin: 0 0 24px -5.5%;

            > div {
                margin: 0;
            }
        }
        .slide {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            min-width: 100%;
            width: 100%;
            max-width: 100%;
            background-image: url('./assets/slideBg.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            img {
                object-fit: cover;
            }

            .details {
                display: inline-flex;
                flex-direction: column;
                width: 100%;
                padding: 24px;
                background-color: rgba(0, 0, 0, 0.5);
                min-height: 180px;

                @media only screen and (max-width: 600px) {
                    min-height: 200px;
                }

                h3 {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: $soft-white;
                    margin: 0 0 24px;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $soft-white;
                    padding: 0 0 24px;
                }
            }
        }
    }
}
