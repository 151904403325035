$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.radioContainer {
    width: 100%;

    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $slate-color;
        margin-bottom: 16px;
    }

    &.lightRadio {
        :global(.ant-radio-wrapper-checked) {
            background-color: $white-smoke-color !important;
        }
    }

    :global(.ant-radio-group) {
        display: flex;
        flex-direction: column;
        gap: 8px;

        :global(.ant-radio-wrapper) {
            border: 1px solid $disabled-color;
            border-radius: 100px;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 8px;

            :global(.ant-radio) {
                position: static;

                &:hover {
                    :global(.ant-radio-inner) {
                        border-color: $disabled-color;
                    }
                }

                :global(.ant-radio-inner) {
                    width: 24px;
                    height: 24px;
                }
            }

            span {
                font-size: 14px;
                line-height: 18px;
                color: $slate-color;
            }
        }

        //checked
        :global(.ant-radio-wrapper-checked) {
            background-color: $turquoise-color;

            :global(.ant-radio-checked) {
                &::after {
                    border: none;
                }

                :global(.ant-radio-inner) {
                    background-color: $turquoise-color;
                    border: 0.6px solid $soft-white;
                    box-shadow: none;
                    border-radius: 50%;

                    &::after {
                        background-color: $turquoise-color;
                        width: 30px;
                        height: 30px;
                        margin-top: -15px;
                        margin-left: -15px;
                        border: 7px solid $soft-white;
                        border-radius: 50%;
                    }
                }
            }
        }

        :global(.ant-input) {
            border-bottom: 1px solid $slate-tint;
            font-size: 14px;
            color: $slate-color;
        }

        :global(.ant-input-disabled) {
            border-bottom: 1px solid $disabled-color;
            color: $disabled-text-color;
        }
    }
}
