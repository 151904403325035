$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 100%;
    z-index: 1;

    &.noPadding {
        padding: 0;
    }

    &.small {
        padding: 5vh 0;
    }

    &.normal {
        padding: 10vh 0;
    }

    &.large {
        padding: 25vh 0;
    }

    &.fullscreen {
        height: 100vh;
    }
}

:global(.ant-skeleton-element) {
    width: 100% !important;

    :global(.ant-skeleton-image) {
        background: $white-smoke-color !important;
        padding: 5vh 0;
        width: 100% !important;

        &:after {
            background: linear-gradient(90deg, $white-smoke-color 25%, hsla(0, 0%, 50.6%, .14) 37%, $white-smoke-color 63%);
        }
    }
}