$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  width: 100%;

  .regions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
    padding: 0 5%;
    margin-bottom: 12px;

    &.square {
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
    }
  }

  .cards {
    position: relative;
    width: 100%;
    height: 145px;

    &.square {
      height: 300px;
    }
  }

  .wrongCount {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 1px solid $accent-yellow;
    background-color: rgba(255, 214, 87, 0.25);
    margin-bottom: 24px;

    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    color: $slate-color;
  }

  @media only screen and (max-width: 375px) {
    .regions {
      margin: 0;
      gap: 4px;

      &.square {
        gap: 8px;
        padding: 0;
      }
    }

    .cards {
      height: 125px;

      &.square {
        height: 185px;

        img {
          max-width: 160px !important;
        }
      }
    }
    .wrongCount {
      margin: 24px 0;
    }
  }
}
