$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    width: 100%;

    @media only screen and (max-width: 640px) {
        width: 100vw;
        margin: 0 -5vw 0 -5vw;
    }

    .container {
        display: flex;
        flex-direction: column;
        background-color: $slate-color;
        margin: 0 0 24px 0;


        img {
            width: 100%;

            &.withPadding {
                padding: 36px 32px 0 32px;

                @media only screen and (max-width: 640px) {
                    padding: 0;
                }
            }
        }

        .text {
            text-align: left;
            padding: 36px 32px;

            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $soft-white;
                margin: 0 0 24px 0;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: $soft-white;
                margin: 0 0 24px 0;
            }

            .disclaimer {
                font-size: 14px;
                margin-top: 10px;
            }

            .badge {
                max-width: 140px;
                .rewardBadge {
                    margin: 0;
                }
            }


            @media only screen and (max-width: 640px) {
                h2 {
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }

    }

    .benefits {
        background-color: $white-smoke-color;
        width: 100%;
        margin: 0 0 24px 0;
        padding: 32px 0 32px 0;


        h2 {
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;

            margin: 0 24px 24px 24px;
            color: $slate-color;
            text-align: left;
        }

        .benefitContainer {
            width: 100%;
            min-width: 400px;
            text-align: left;

            .benefit {
                height: 100%;
                padding: 24px 24px;
                border-radius: 4px;
                background-color: $soft-white;

                img {
                    margin-bottom: 16px;
                }

                h2 {
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 120%;
                    color: $slate-color;
                    margin: 0 0 16px 0;

                }

                p {

                    font-size: 14px;
                    line-height: 18px;
                    color: $slate-color;
                    margin: 0 0 19px 0;
                }

            }

            @media only screen and (max-width: 640px) {
                min-width: calc(100vw - 48px);
            }
        }
    }

    .tabs {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;


        :global(.ant-tabs) {
            width: 100%;


            :global(.ant-tabs-nav) {
                border-radius: 100px;
                padding: 4px;
                background-color: #e4e4e4;
                width: 100%;
                margin: 0;

                &::before {
                    border: none;
                }

                :global(.ant-tabs-nav-list) {
                    width: 100%;

                    :global(.ant-tabs-tab) {
                        width: 33.3%;
                        border-radius: 82px;
                        background-color: #e4e4e4;
                        justify-content: center;
                        border: none;


                    }

                    :global(.ant-tabs-tab:hover) {
                        color: $slate-color;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    :global(.ant-tabs-tab-active) {
                        background-color: #fffdfb;
                    }

                    :global(.ant-tabs-tab-btn) {
                        color: $slate-color;
                        text-shadow: none;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                @media only screen and (max-width: 640px) {
                    width: 85%;
                    margin: 0 auto;
                }

            }

            :global(.ant-tabs-content-holder) {
                width: 100%;
                margin: 24px 0 0 0;
            }
        }
    }

    .tutorial {
        background-color: $white-smoke-color;
        margin: 0 0 24px 0;
        padding: 36px 24px;

        video {
            background-color: #C4C4C4;
            width: 100%;
            height: 488px;
            position: relative;
            z-index: 1;
        }
    }

    .instructions {
        padding: 0 24px;
        margin: 0 0 24px 0;

        .instruction {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;

            &:not(:last-child) {
                margin: 0 0 16px 0;
            }

            span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                background: $turquoise-color;
                border-radius: 100%;
                color: $slate-color;
                width: 19px;
                margin: 0 8px 0 0;
                flex: 0 0 19px;
            }
        }



        @media only screen and (max-width: 640px) {
            width: 85%;
            margin: 0 auto;
            padding: 0;
        }
    }
}