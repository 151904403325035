$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    width: 100%;

    margin: 0;

    @media only screen and (max-width: 750px) {
        &.fullWidth {
            width: 100vw;
            margin: 0 0 0 -5.5%;
        }
    }

    .slide {
        display: flex !important;
        height: 375px;
        width: 100%;

        @media only screen and (max-width: 570px) {
            flex-direction: column-reverse;
            height: auto;
        }

        &.columnReverse {
            @media only screen and (max-width: 570px) {
                flex-direction: column;
            }

            :global(.slick-dots) {
                &:global(.slick-dots-bottom) {
                    top: 25px !important;
                }
            }
        }

        &.belowDescription {
            flex-direction: column;
            gap: 24px;
            text-align: center;
            height: auto;

            h3 {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            }

            p {
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }

            .bold {
                font-weight: 700;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            background: $slate-color;
            padding: 75px 25px 35px;
            width: 50%;
            float: left;

            &.single {
                padding: 35px 25px;
            }

            @media only screen and (max-width: 850px) {
                padding: 75px 25px 35px 5%;
            }

            @media only screen and (max-width: 570px) {
                min-height: 270px;
                width: 100%;

                &:not(.single) {
                    padding: 55px 25px 35px;
                }
            }

            @media only screen and (max-width: 420px) {
                min-height: 280px;
            }

            .details {
                display: inline-block;
                width: 100%;
                float: left;
                text-align: left;

                h3 {
                    color: $soft-white;
                    line-height: 24px;
                    font-weight: bold;
                    font-size: 18px;

                    text-align: left;
                    margin: 0 0 15px;
                    width: 100%;
                    float: left;

                    img {
                        max-width: 150px;
                    }

                    span {
                        color: $turquoise-color;
                    }
                }

                p {
                    color: $soft-white;
                    line-height: 20px;
                    font-size: 16px;

                    width: 100%;
                    float: left;
                    margin: 0;

                    b,
                    strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .visual {
            display: inline-block;
            position: relative;
            float: right;
            width: 50%;

            @media only screen and (max-width: 570px) {
                height: 280px;
                width: 100%;
            }

            img {
                object-position: center;
                object-fit: cover;
                background: #ddd;

                height: 100%;
                width: 100%;
                float: left;
            }
        }

        // Text modes

        .darkTitle,
        .darkDescription {
            color: $slate-color !important;
        }
    }

    // Carousel overwrites
    :global(.slick-arrow) {
        position: absolute;
        z-index: 1;
        left: 25px;
        top: 35px;

        &:global(.slick-prev) {
            transform: rotate(-180deg);
            left: 30px;
            top: 39px;

            @media only screen and (max-width: 850px) {
                left: 6%;
            }
        }

        &:global(.slick-next) {
            right: auto;
            left: 75px;
        }
    }

    &.turquoiseArrows {
        :global(.slick-arrow) {
            svg {
                path {
                    stroke: $turquoise-color;
                }
            }
        }
    }

    :global(.slick-dots) {
        &:global(.slick-dots-bottom) {
            bottom: auto;
            left: 25px;
            top: 35px;

            justify-content: flex-start;
            margin: 0;
        }
    }

    &.bottomButtons {
        :global(.slick-dots) {
            &:global(.slick-dots-bottom) {
                bottom: -16px !important;
                left: 0;
                right: 0;
                justify-content: center;
                margin: auto;
                top: auto !important;

                :global(.slick-active button) {
                    background: $turquoise-color;
                }

                :global(li button) {
                    background: $disabled-text-color;
                }
            }
        }
    }

    // Custom classnames
    .exampleClassname {
        .content {
        }

        .visual {
        }
    }

    @media only screen and (max-width: 650px) {
        padding: 24px 0 0 0;
    }
}
