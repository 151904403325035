$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    .space {
        margin: 24px 0;
    }

    .expertAdvice {
        background-color: rgba(0, 209, 210, 0.25);
        border: 1px solid #00d1d2;
        border-radius: 5px;
        padding: 16px 20px;
        margin:0 0 24px !important;

        .description {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
            color: $soft-white;
        }
    }

    .listContainer {
        :global(.content){
            margin:24px 0;

            h3 {
                color: $soft-white!important;
            }
            p {
                color: $soft-white;
            }
            .deviceListItem {
                margin-bottom: 16px;
                h3 {
                    margin-bottom: 8px;
                    font-size:18px;
                    color: $soft-white!important;
                }
                p {
                    color: $soft-white;
                }
            }
        }
        
    }
}
