$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  width: 100%;
  max-width: 592px;
  aspect-ratio: 1;
  //background-image: url('../../assets/card-background.png');
  background-color: $turquoise-color;
  border-radius: 17px;

  @media only screen and (max-width: 1300px) {
    aspect-ratio: auto;
  }

  position: relative;

  .backdropFilter {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00000059;
    border-radius: 30px;
  }

  &.customBackground {
    background-image: url('../../assets/background.gif');
    background-size: cover;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (min-width: 450px) and (max-width: 850px) {
      aspect-ratio: 1;
    }

  }

  &.square {

    aspect-ratio: 1 !important;

  }

  // &.winner {
  //   background-image: url('../../assets/card-background-winner.png');
  //   background-size: cover;
  //   background-color: transparent;
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }

  // &.winnerVoucher {
  //   background-image: url('../../assets/card-background-winner-voucher.png');
  //   background-size: cover;
  //   background-color: transparent;
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }

  &.largeText {
    .childrenContainer {
      @media only screen and (min-width: 600px) {
        padding: 20px;
        max-width: 90%;
        > div {
          max-width: 70%;
          gap: 20px;
        }

        h3, h2 {
          font-size: 45px;
          line-height: 50px;

          strong {
            font-size: 45px;
            line-height: 50px;
          }
        }

        p {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
  }

  .childrenContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 26px;
    user-select: none;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    max-width: 450px;
    margin: auto;

    @media only screen and (max-width: 600px) {
      padding: 15px;
    }

    h2 {
      font-size: min(6.4vw, 43.5px);
      line-height: 28px;
      font-weight: 700;
      color: $slate-color;
      text-align: center;
    }

    h3 {
      font-size: min(6.4vw, 43.5px);
      line-height: 21px;
      font-weight: 700;
      color: $slate-color;
      text-align: center;
      margin-bottom: min(4.8vw, 32.5px);

      @media only screen and (max-width: 600px) {
        line-height: 31px;
      }
    }

    p {
      font-size: min(3.7vw, 25.3px);
      line-height: min(4.8vw, 32.5px);
      font-weight: 400;
      color: $slate-color;
      text-align: center;
    }

    strong {
      font-size: min(5vw, 25.3px);
    }
  }
}
