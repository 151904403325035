$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    width: 100%;

    .content {
        .marginTop {
            margin-top: 24px;
        }
    }

    .redirectToMyProfile {
        cursor: pointer;
        text-decoration: underline;
    }

    .hyperlink {
        color: $slate-color;
        border-bottom: 1px solid $slate-color;
        line-height: 20px;
        display: inline-block;

        &:hover {
            border-bottom-color: $turquoise-color;
        }
    }

    .pinTitle {
        @media only screen and (max-width: 870px) {
            background-color: #f6f4f0;
            padding: 16px;
            border-radius: 10px;
            margin-bottom: 0;
        }

        h3 {
            cursor: pointer;
        }
    }
}
