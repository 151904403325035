$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 21px 0;

  :global(.headerGif) {
    // max-width: 100%;
    // width: 70%;
    // height: 150px;
    // object-fit: cover;
    position: relative;
    max-width: 100%;
    margin: 0 0 15px;

    @media only screen and (max-width: 600px) {
      position: relative;
      max-width: 100%;
      width: 100%;
    }
  }

  .button {
    margin: 20px 0 0;

  }

  .badgesContainer {
    max-width: 290px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto 16px;

    @media only screen and (max-width: 600px) {
      max-width: 240px;
    }

    .badgeItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      svg {
        width: 40px;
        height: 40px;
        min-width: 40px;
      }

      p {
        font-size: min(3.7vw, 20px);
        line-height: min(4.8vw, 21px);
        font-weight: 700;
        color: $slate-color;
        text-align: left;
        margin: 0;
      }
    }
  }

  h2 {
  }
}
