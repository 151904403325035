$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.stardriftQuizContainer {
    .quizCard {
        position: relative;

        @media only screen and (min-width: 1240px) {
            display: flex;
        }

        .desktopImage {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 6px;
            z-index: 1;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .quizCardBackground {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .cardContent {
            position: relative;
            z-index: 1;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;

            @media only screen and (min-width: 1240px) {
                width: 50%;
            }

            .progressContainer {
                position: relative;
                width: 100%;
                max-width: 327px;
                margin: 35px auto 0 auto;

                .tooltipContainer {
                    background-color: $white-smoke-color;
                    border-radius: 4px;
                    position: absolute;
                    padding: 1px 2px;
                    top: -27px;

                    height: 24px;
                    width: 36px;

                    .tooltip {
                        line-height: 16px;
                        font-weight: 700;
                        font-size: 12px;

                        color: $slate-color;
                        text-align: center;
                        padding: 2px 0 0 0;
                    }

                    .point {
                        position: absolute;
                        margin-right: auto;
                        margin-left: auto;

                        bottom: -10px;
                        right: 0;
                        left: 0;

                        height: 0;
                        width: 0;

                        border-right: 5px solid transparent;
                        border-left: 5px solid transparent;
                        border-top: 10px solid $white-smoke-color;
                    }
                }

                :global(.ant-progress) {
                    margin: 0;

                    :global(.ant-progress-inner) {
                        background-color: $white-smoke-color;

                        :global(.ant-progress-bg) {
                            background-color: $turquoise-color;
                        }
                    }
                }
            }

            .cardImageWrapper {
                width: 100%;

                img {
                    width: 100%;
                    max-height: 325px;
                    object-fit: contain;
                }
            }

            .correctOrWrongAnswers {
                .answerTitle {
                    font-size: 20px;
                    line-height: 22px;
                    color: $white-smoke-color;
                    text-align: left;
                    margin-bottom: 24px;
                }

                .answerSubtitle {
                    text-align: left;
                    font-size: 16px;
                    line-height: 20px;
                    color: $white-smoke-color;
                    margin: 0;
                }
            }

            .quizQuestionsContainer {
                .quizQuestionsDetails {
                    h3 {
                        color: $soft-white;
                        font-size: 20px;
                        line-height: 22px;
                        margin-bottom: 24px;
                        text-align: left;
                    }

                    .form {
                        :global(.ant-radio-group) {
                            gap: 24px;

                            :global(.ant-radio-wrapper) {
                                border: none;
                                padding: 0;

                                &:hover {
                                    :global(.ant-radio-inner) {
                                        border-color: $light-grey-border;
                                    }
                                }

                                span {
                                    color: $soft-white;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    padding: 0;
                                    text-align: left;
                                }

                                :global(.ant-radio) {
                                    margin: 0 16px 0 0;
                                    :global(.ant-radio-inner) {
                                        background-color: transparent;
                                        border-radius: 5px;
                                    }
                                }
                            }

                            :global(.ant-radio-wrapper-checked) {
                                border: none;
                                background-color: transparent !important;

                                span {
                                    color: $soft-white;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                }
                                :global(.ant-radio) {
                                    :global(.ant-radio-inner) {
                                        background-color: $turquoise-color;
                                        border-radius: 5px;
                                        border: 1px solid $soft-white;

                                        &:after {
                                            background-image: url(../assets/checkSign.svg);
                                            background-repeat: no-repeat;
                                            background-color: transparent;
                                            background-size: 115%;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .successButtonsWrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;
            max-width: 200px;

            @media only screen and (min-width: 500px) {
                max-width: 100%;
            }
        }
    }
}

.stardriftQuizCompletedModal {
    h3 {
        font-size: 29px;
        line-height: 32px;
    }

    p {
        font-size: 14px;
        line-height: 18px;
    }

    .modalQuizReward {
        display: inline-block;
        background: $white-smoke-color;
        padding: 4px 10px 4px 4px;
        border-radius: 50px;
        margin: 16px 0;
        left: 25px;
        top: 25px;

        svg {
            float: left;
        }

        span {
            display: inline-block;
            margin: 2px 0 0 10px;

            line-height: 14px;
            font-size: 12px;

            b {
                font-size: 14px;
            }
        }
    }
}
