$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {


  .noMargin {
    margin: 0;
  }

  .marginTop {
    margin-top: 12px;
  }

  .articleWrapper {

    margin-bottom: 24px;
  }

  .fullWidthImage {

    padding: 24px;

    h3 {
      margin-bottom: 20px;
      text-align: left;
    }

    p {
      text-align: left;
    }

    @media only screen and (max-width: 750px) {

      padding: 0 24px 24px;

      img {
        width: 100vw;
        margin: 0 0 0 -24px;
        object-fit: cover;
      }

    }

  }

  .customContainer {
    text-align: left;
    padding: 24px;
    margin-bottom: 24px;

    h3 {
      margin-bottom: 20px;
    }

    .noMargin {
      margin-bottom: 0 !important;

    }


    .prizesSlider {


      .slide {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-size: 28px;
          line-height: 30px;
        }

        p {
          margin: 0;
        }

        img {
          width: 100%;

        }
      }
    }
  }


}
