$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    text-align: left;
    margin: 0;
    width: 100%;
    float: left;

    .content {
        display: block;
        max-width: calc(100% - 140px);
        margin: 0 auto;
        width: 90%;

        @media only screen and (max-width: 850px) {
            max-width: 100%;
            width: 100%;
        }

        h1 {
            line-height: 32px;
            font-size: 28px;

            margin: 0 0 24px;
        }

        h2 {
            line-height: 28px;
            font-size: 21px;

            margin: 0 0 24px;
        }
    }

    .image {
        display: inline-block;
        position: relative;
        width: 100%;
        float: left;

        @media only screen and (max-width: 850px) {
            margin: 0 0 0 -5.5%;
            width: 111%;
        }

        img {
            display: inline-block;
            object-fit: cover;
            height: 375px;
            width: 100%;
            float: left;
        }

        .isVideo {
            display: inline-block;
            position: relative;
            cursor: pointer;
            width: 100%;
            float: left;

            svg {
                position: absolute;
                left: calc((100% - 50px) / 2);
                top: calc((100% - 50px) / 2);
            }
        }

        .badges {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            position: absolute;
            gap: 10px;

            left: 16px;
            top: 16px;

            .badgeIcon{
                display: flex;
                align-items: center;
                justify-content: center;

                background: $soft-white;
                border-radius: 50px;
                padding: 3px;

                height: 42px;
                width: 42px;

                svg{
                    width: 100%;
                    float: left;
                }
            }
        }

        .likeButton {
            border: 3px solid $white-smoke-color;
            background: $white-smoke-color;
            border-radius: 50px;
            position: absolute;
            cursor: pointer;

            bottom: -24px;
            right: 28px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 24px;
            height: 48px;
            width: 48px;

            &.liked {
                background: $turquoise-color;
                color: $slate-color;
            }
        }
    }
}

.videoModal {
    :global(.ant-modal-body) {
        @media only screen and (max-width: 750px) {
            padding: 0 !important;
        }
    }
}
