$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 388px;
  min-width: 282px;
  padding: 25px 25px 30px;
  max-width: calc(50% - 8px);
  background-color: $white-smoke-color;

  &.turquoiseCard {
    background-color: $turquoise-color;
  }

  .thumbnail {
    display: inline-block;
    position: relative;
    width: 100%;
    float: left;
    margin: 0;

    img {
      margin-bottom: 10px;
      object-fit: cover;
      height: 150px;
      width: 100%;
    }

    // For seen icon only
    > svg {
      display: inline-block;
      position: absolute;

      left: 16px;
      top: 16px;
    }

    .pointsIcon {
      display: flex;
      align-items: center;
      position: absolute;

      background-color: $white-smoke-color;
      border-radius: 100px;

      left: 16px;
      top: 16px;

      width: 105px;
      height: 25px;

      .icon {
        margin: 0 4px 0 2px;
        height: 20px;
        width: 20px;
      }

      .pointsContainer {
        display: flex;
        flex-direction: column;

        .number {
          margin: 0;
          padding: 0;
          font-size: 11px;
          line-height: 9px;
          color: $slate-color;
        }

        .text {
          margin: 0;
          padding: 0;
          font-size: 10px;
          line-height: 10px;
          color: $slate-color;
        }
      }
    }

    .badgeContainer {
      position: absolute;
      left: 136px;
      top: 5px;

      @media only screen and (max-width: 1400px) {
        transform: scale(0.7);
        left: 100px;

        &.badgeNoFp {
          left: 10px;
        }
      }

      &.badgeNoFp {
        left: 10px;
      }


      .badge {
        background: $white-smoke-color;
        display: inline-block;
        border-radius: 50px;
        margin-right: 8px;
        padding: 4px;


        svg {
          float: left;
        }
      }
    }

  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;

    .date {
      line-height: 21px;
      font-weight: 400;
      font-size: 14px;

      color: #726f78;
      margin: 0;
    }

    .title {
      line-height: 21px;
      font-weight: 700;
      font-size: 18px;

      margin: 8px 0 16px 0;
      max-width: 231px;
      color: $slate-color;
    }

    .description {
      margin: 0 0 10px 0;
      color: $slate-color;

      p {
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;

        // Fix for Safari line-clamp issue.
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }

  .arrow {
    margin: auto 0 0 0;

    img {
      cursor: pointer;
    }
  }
}
