$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  .autoTopContent {
    .subtitle {
      font-weight: 700;
    }
  }


  @media only screen and (min-width: 751px) {
    .marginTop {
      margin-top: 12px;
    }

    .noMarginTop {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  .marginTop {
    a {
      border-bottom: 1px solid $slate-color;
      color: $slate-color;
      line-height: 24px;

      &:hover {
        border-bottom: 1px solid $turquoise-color;
      }
    }

  }

  p {
    .listIndex {
      color: $turquoise-color;
      font-weight: bold;
    }
  }

  .iconList {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin: 16px 0;
    flex: 1;

    .list {
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        margin: 0 0 8px 0;
      }

      .subtitle {
        font-size: 14px;
        line-height: 18px;

        a {
          color: $slate-color;
          text-decoration: underline;
        }
      }

      p {
        margin-top: 8px;
        font-size: 14px;
        line-height: 18px;

        .listIndex {
          color: $turquoise-color;
          font-weight: bold;
        }
      }
    }

    p {
      margin: 0;
      line-height: 20px;
    }
  }
}
