$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    width: 100%;

    .textVideoContainer {
        display: flex;
        gap: 16px;
        padding: 24px;
        background-color: $white-smoke-color;
        margin-bottom: 24px;
        align-items: center;

        @media only screen and (min-width: 1240px) and (max-width: 1330px) {
            flex-direction: column-reverse;
            padding: 0;

            .details {
                padding: 24px;
            }
        }

        @media only screen and (min-width: 850px) and (max-width: 1030px) {
            flex-direction: column-reverse;
            padding: 0;

            .details {
                padding: 24px;
            }
        }

        @media only screen and (max-width: 650px) {
            flex-direction: column-reverse;
            padding: 0;
            width: 112%;
            margin: 0 0 0 -5.5%;

            .details {
                padding: 24px;
            }
        }

        .vimeoPlayer {
            width: 100%;
            height: 100%;
            min-width: 279px;
            aspect-ratio: 9/16;
            border-radius: 10px;

            iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .details {
            text-align: left;
            width: 100%;

            h3 {
                font-size: 24px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
    }

    .customContainer {
        text-align: left;
        background-color: $slate-color;
        padding: 24px;

        @media only screen and (max-width: 650px) {
            width: 112%;
            margin: 0 0 0 -5.5%;
            padding: 0;

            h3 {
                padding: 24px 24px 0;
            }

            p {
                padding: 24px 24px;
            }

            :global(.keen-arrows) {
                padding: 20px 16px 20px 0;
            }
        }

        h3 {
            color: $white-smoke-color;
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 28px;
        }

        p {
            color: $white-smoke-color;
        }

        .prizesSlider {
            .slide {
                img {
                    width: 100%;
                }
            }
        }
    }
}
