$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  display: flex;
  background-color: $white-smoke-color;

  margin: 0 0 24px;
  padding: 36px;

  width: 100%;
  gap: 30px;

  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
    padding: 24px;
    gap: 0;
  }

  .thumbnail {
    display: flex;
    align-items: center;
    width: 180px;

    img {
      min-height: 150px;
      min-width: 180px;

      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    @media only screen and (max-width: 750px) {
      width: 100%;
    }
  }

  .details {
    :global(.ant-input) {
      border-bottom: 1px solid $slate-tint;
      font-weight: bold;

      &::placeholder {
        color: $slate-tint;
      }

      ::-moz-placeholder {
        color: $slate-tint;
      }

      ::-webkit-input-placeholder {
        color: $slate-tint;
      }
    }

    button {
      span {
        line-height: 22px;
      }
    }

    .warning {
      display: inline-block;
      font-size: 12px;
      margin: 5px 0 0;
      width: 100%;
      float: left;

      svg {
        height: 20px;
        width: 20px;

        vertical-align: sub;
        position: relative;
        margin-right: 5px;
        top: 3px;
      }
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 850px) {
        margin: 0 0 20px 0;
      }

      :global(.rewardBadge) {
        transform: scale(0.9);
        left: -10px;
        top: -2px;

        margin: 0;
      }

      .badgeIcon {
        position: relative;
        left: -5px;

        svg {
          margin: 4px;
        }
      }
    }

    .remainingCodesDisclaimer {
      border: 1px solid $turquoise-color;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 16px;
      display: inline-block;

      &.redDisclaimer {
        border: 1px solid #db3624;
      }

      .text {
        font-size: 14px;
        line-height: 18px;
        color: $slate-color;
        margin: 0;
      }
    }

    h3 {
      line-height: 26px;
      font-weight: 700;
      font-size: 21px;

      color: $slate-color;
      margin: 0 0 10px;
    }

    p {
      line-height: 18px;
      font-size: 14px;

      color: $slate-color;
      margin: 0 0 16px;
    }

    &.active {
      h3 {
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 30px;
      }
    }
  }

  &.isActive {
    .thumbnail {
      display: inline-block;

      img {
        max-height: 150px;
      }
    }
  }
}

.findCode {
  :global(.ant-tooltip-inner) {
    p {
      text-align: center;
      color: $slate-color;
      padding: 5px 5px 0;
      margin: 0;

      line-height: 1.5;
      font-size: 14px;
    }

    img {
      display: block;
      margin: auto;
      width: 100%;
    }
  }
}

.disclaimer {
  background-color: $turquoise-disclaimer;
  border: 1px solid $turquoise-color;
  border-radius: 5px;
  padding: 16px;
  margin-top: 20px;
  font-size: 12px;
  color: $slate-color;

  &.red {
    background-color: $red-disclaimer;
    border-color: $accent-red;
  }

  &.successStyle {
    padding: 16px 20px;
  }

  &.fullWidth {
    text-align: center;
    padding: 16px;
    width: 100%;
  }
}

.toArticle {
  display: inline;
}

.codesModal {
  :global(.ant-modal-body) {
    padding: 36px !important;
  }

  @media only screen and (max-width: 640px) {
    height: fit-content !important;
    width: 100%;
    padding: 0;
    margin: 0;

    :global(.ant-modal) {
      height: fit-content !important;
      max-width: 100% !important;
      width: 100%;
      margin: 0;
    }
    :global(.ant-modal-content) {
      height: fit-content;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 16px;

    .successIcons {
      display: flex;
      gap: 24px;

      div:first-of-type {
        padding: 6px 10px 0px 4px!important;
      }

      .badgeIcon {
        position: relative;
        left: -5px;
  
        svg {
          margin: 4px;
        }
      }
  
    }

    h2 {
      line-height: 32px;
      font-size: 28px;

      margin: 0;
    }

    .order {
      order: -1;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $slate-color;

      a {
        color: $slate-color;
        text-decoration: underline;
        text-decoration-color: $turquoise-color;
        text-underline-offset: 3px;
      }
    }

    .disclaimer {
      width: 100%;
      margin: 0;

      span {
        display: block;
      }
    }

    div {
      margin: 0;

      .successButton {
        margin-top: 8px;
      }
    }

    .bordered {
      border-radius: 4px;
      border: 1px solid $turquoise-color;
      padding: 16px;
    }
  }
}
