$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  width: 100%;

  .noMargin {
    margin: 0;
  }

  .marginTop {
    margin-top: 12px;
  }

  .noMarginTop {
    margin-top: 0;


  }

  .noMarginbottom {
    margin-bottom: 0;
  }

  .fullWidthContent {
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
  }

  .experienceSlider {
    padding: 16px;
    position: relative;
    text-align: left;

    :global(.keen-arrows) {
      color: $soft-white;
    }

    .leftDots {
      justify-content: flex-start;
    }

    .sliderDots {

      @media only screen and (max-width: 867px) {
        bottom: 220px;
      }

      @media only screen and (max-width: 650px) {
        bottom: 200px;
      }

      @media only screen and (max-width: 467px) {
        bottom: 220px;
      }

      @media only screen and (max-width: 400px) {
        bottom: 288px;
      }

      position: absolute;
      bottom: 200px;


    }

    :global(.keen-slider) {


      .slideItem {
        min-width: calc(330px + 5vw);
        max-width: calc(330px + 5vw);
        width: calc(330px + 5vw);
        text-align: left;

        @media only screen and (max-width: 650px) {
          min-width: calc(350px + 5vw);
          max-width: calc(350px + 5vw);
          width: calc(350px + 5vw);
        }

        img {
          width: 100%;
          max-height: 633px;
          object-fit: cover;
        }

        h3 {

          &:first-of-type {
            margin: 16px 0 8px;
            min-height: 50px;
          }

          margin: 36px 0 16px;

        }

        p {

          @media only screen and (max-width: 400px) {
            max-width: 280px;
          }

          margin: 0;
        }
      }
    }

  }


}
