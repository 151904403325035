$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .quiz {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    padding: 16px 24px;

    .title {
      color: $slate-color;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;
    }

    .radioContainer {
      width: 100%;

      :global(.ant-radio-group) {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 250px;
        width: fit-content;
        margin: 0 auto;

        :global(.ant-radio-wrapper) {
          border: 1px solid $disabled-color;
          border-radius: 100px;
          margin: 0;
          display: flex;
          align-items: center;
          padding: 8px;

          :global(.ant-radio) {
            position: static;

            &:hover {
              :global(.ant-radio-inner) {
                border-color: $disabled-color;
              }
            }

            :global(.ant-radio-inner) {
              width: 24px;
              height: 24px;
            }
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: $slate-color;
          }
        }

        :global(.ant-radio-wrapper-checked) {
          background-color: $turquoise-color;

          :global(.ant-radio-checked) {
            &::after {
              border: none;
            }

            :global(.ant-radio-inner) {
              background-color: $turquoise-color;
              border: 0.6px solid $soft-white;
              box-shadow: none;
              border-radius: 50%;

              &::after {
                background-color: $turquoise-color;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                margin-left: -15px;
                border: 7px solid $soft-white;
                border-radius: 50%;
              }
            }
          }
        }

        :global(.ant-input) {
          border-bottom: 1px solid $slate-tint;
          font-size: 14px;
          color: $slate-color;
        }

        :global(.ant-input-disabled) {
          border-bottom: 1px solid $disabled-color;
          color: $disabled-text-color;
        }
      }
    }

    @media only screen and (max-width: 640px) {
      padding: 16px 8px;
    }
  }

  .completed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    padding: 24px;
    margin: 24px 0;
    border-radius: 20px;
    background: whitesmoke;


    h3 {
      line-height: 22px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      width: 100%;
      color: $slate-color;
      margin: 0 0 16px;
    }


    .poll {
      display: inline-block;
      margin: 8px auto 0;
      width: 100%;
      float: left;

      @media screen and (max-width: 640px) {
        margin: 0;
      }

      h4 {
        text-align: left;
        font-size: 18px;
      }

      .otherResponses {
        text-transform: none;
      }

      .result {
        display: inline-block;
        position: relative;
        width: 100%;
        float: left;

        &:not(:last-of-type) {
          margin: 0 0 8px;
        }

        .mostVoted {
          font-weight: bold;
        }

        .name {
          position: absolute;
          z-index: 1;
          left: 8px;
          top: 65%;

          text-align: left;
          font-size: 16px;
          line-height: 1;

          @media screen and (max-width: 640px) {
            max-width: 250px;
          }
        }

        :global(.ant-progress) {
          display: flex;
          align-items: center;
          justify-content: center;

          height: 50px;

          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }

        :global(.ant-progress-inner) {
          background: $soft-white;
          border-radius: 10px;
          height: 50px;
        }

        :global(.ant-progress-text) {
          position: absolute;
          top: 78%;
          right: 16px;
          font-weight: bold;
        }

        :global(.ant-progress-outer) {
          width: 100% !important;
          padding: 0;
          margin: 0;
        }

        :global(.ant-progress-bg) {
          height: 50px !important;
          border-radius: 10px;
          float: left;
        }
      }
    }
  }
}