$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.chancesFromBRModal {
  :global(.ant-modal-body) {
    padding: 55px 25px 25px;

    @media only screen and (max-width: 960px) {
      display: inline-block;
      max-height: 83vh;
      overflow-y: auto;
    }
  }

  .chancesFromBRModalContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    padding: 20px 0 0;
    @media only screen and (max-width: 960px) {
      padding: 0;
    }

    .image {
      position: relative;
      display: flex;

      .smallBadge {
        top: 10px;
        left: 10px;
      }

      img {
        border-radius: 10px;
        max-width: 100%;
      }
    }

    
    .title {
      display: inline-flex;
      font-weight: 700;
      font-size: 29px;
      line-height: 32px;
      color: $slate-color;

      margin: 25px 0;
      text-align: left;
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      color: $slate-color;

      margin: 0 0 25px;
    }
  }
}
