$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    .imageWrapper {
        @media only screen and (max-width: 600px) {
            width: 111%;
            margin: 0 0 0 -5.5%;
        }
        img {
            max-width: 100%;
        }
    }

    .leviaSlider {
        @media only screen and (max-width: 600px) {
            width: 111%;
            margin: 0 0 0 -5.5%;
        }

        .sliderWrapper {
            margin: 0;

            .slider {
                width: 100%;
                margin: 0;
                position: relative;

                :global(.keen-arrows) {
                    @media only screen and (max-width: 769px) {
                        padding: 0 20px 24px 0;
                    }
                }

                :global(.keen-slider) {
                }

                .leviaSliderDots {
                    position: absolute;
                    bottom: 16px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .slide {
                    position: relative;
                    display: inline-flex;
                    flex-direction: column;
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;

                    .cardBackgroundImage {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }

                    .cardContent {
                        padding: 47px 25px 41px 25px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .invite {
                            font-size: 20px;
                            line-height: 22px;
                            color: $white-smoke-color;
                            margin-bottom: 24px;
                        }

                        .pack {
                            max-width: 285px;
                            margin-bottom: 24px;
                        }

                        .flavour {
                            width: 70px;
                            height: 70px;
                            margin-bottom: 6px;
                        }

                        .title {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: bold;
                            color: $white-smoke-color;
                            margin-bottom: 0;
                            text-align: center;
                        }

                        .titleDescription {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: $white-smoke-color;
                            margin-bottom: 16px;
                            text-align: center;
                        }

                        .aroma {
                            font-size: 16px;
                            line-height: 20px;
                            color: $white-smoke-color;
                            text-align: center;
                        }

                        .contentTitle {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: $white-smoke-color;
                            margin-bottom: 16px;
                            text-align: center;
                            height: 50px;
                            max-width: 312px;

                            @media only screen and (max-width: 342px) {
                                height: 65px;
                            }
                        }

                        .contentSubtitle {
                            font-size: 12px;
                            line-height: 16px;
                            color: $white-smoke-color;
                            margin-bottom: 16px;
                            text-align: center;
                            max-width: 312px;
                        }

                        .sliderButtons {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 16px;

                            .listenSound {
                                width: 155px;
                                display: flex;
                                gap: 16px;
                                justify-content: center;

                                p {
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-weight: bold;
                                    margin: 0;
                                }

                                img {
                                    width: 19px;
                                    height: 19px;
                                }
                            }

                            .redeemPack {
                                width: 155px;
                            }

                            .orderNow {
                                width: 155px;
                                box-shadow: inset 0 0 0 2px #34303d;
                                background: #34303d;
                                color: #fffdfb;

                                display: inline-block;
                                -webkit-appearance: none !important;
                                position: relative;
                                text-decoration: none;
                                appearance: none;
                                cursor: pointer;
                                outline: none;
                                border: none;
                                text-align: center;
                                transition: background 0.2s, box-shadow 0.3s ease-out, color 0.2s;
                                will-change: background, box-shadow, color;
                                border-radius: 50px;
                                padding: 13px 25px;
                                overflow: hidden;
                                z-index: 1;
                                font-size: 14px;

                                &:after {
                                    background: $turquoise-color;
                                    position: absolute;
                                    content: ' ';
                                    z-index: -1;
                                    opacity: 1;

                                    height: 120%;
                                    width: 120%;

                                    top: 50%;
                                    left: -1px;

                                    transition: transform 0.25s ease-out;
                                    transform: translate(-100%, -50%) skew(0deg);
                                }

                                &:hover {
                                    transition: background 0.15s 0.2s,
                                        box-shadow 0.15s 0.25s ease-out, color 0.2s;
                                    box-shadow: inset 0 0 0 2px transparent;

                                    text-decoration: none;
                                    outline-width: 0;

                                    &:after {
                                        transform: translate(-10%, -50%) skew(-30deg);
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .smallDisclaimer {
                            font-size: 12px;
                            line-height: 16px;
                            color: $white-smoke-color;
                            margin: 16px 0 0 0;
                            text-align: center;

                            a {
                                font-size: 12px;
                                line-height: 16px;
                                color: $white-smoke-color;
                                text-decoration: underline;
                            }
                        }

                        .disclaimer {
                            padding: 8px;
                            background-color: $disabled-color;
                            border-radius: 5px;
                            opacity: 75%;
                            margin: 16px 0 0 0;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                            max-width: 320px;

                            p {
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: $slate-color;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .desktopCards {
        display: flex;

        .slide {
            display: flex;
            width: 50%;
            position: relative;

            .cardBackgroundImage {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            .cardContent {
                padding: 38px 25px;
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 100%;

                .pack {
                    max-width: 285px;
                    margin-bottom: 0;
                    object-fit: contain;
                    width: 100%;
                }

                .flavour {
                    width: 51px;
                    height: 51px;
                    margin-bottom: 10px;
                }

                .aroma {
                    font-size: 16px;
                    line-height: 20px;
                    color: $white-smoke-color;
                    text-align: center;
                }

                .title {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: bold;
                    color: $white-smoke-color;
                    margin-bottom: 0;
                    text-align: center;
                }

                .titleDescription {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    color: $white-smoke-color;
                    margin-bottom: 16px;
                    text-align: center;
                }

                .contentTitle {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    color: $white-smoke-color;
                    margin-bottom: 16px;
                    text-align: center;
                    max-width: 236px;
                    height: 70px;

                    @media only screen and (min-width: 1231px) and (max-width: 1350px) {
                        height: 100px;
                    }
                }

                .contentSubtitle {
                    font-size: 12px;
                    line-height: 16px;
                    color: $white-smoke-color;
                    margin-bottom: 16px;
                    text-align: center;
                    max-width: 232px;
                }

                .sliderButtons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 0;

                    .listenSound {
                        width: 155px;
                        display: flex;
                        gap: 16px;
                        justify-content: center;

                        p {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            margin: 0;
                        }

                        img {
                            width: 19px;
                            height: 19px;
                        }
                    }

                    .redeemPack {
                        width: 155px;
                    }

                    .orderNow {
                        width: 155px;

                        box-shadow: inset 0 0 0 2px #34303d;
                        background: #34303d;
                        color: #fffdfb;

                        display: inline-block;
                        -webkit-appearance: none !important;
                        position: relative;
                        text-decoration: none;
                        appearance: none;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        text-align: center;
                        transition: background 0.2s, box-shadow 0.3s ease-out, color 0.2s;
                        will-change: background, box-shadow, color;
                        border-radius: 50px;
                        padding: 13px 25px;
                        overflow: hidden;
                        z-index: 1;
                        font-size: 14px;

                        &:after {
                            background: $turquoise-color;
                            position: absolute;
                            content: ' ';
                            z-index: -1;
                            opacity: 1;

                            height: 120%;
                            width: 120%;

                            top: 50%;
                            left: -1px;

                            transition: transform 0.25s ease-out;
                            transform: translate(-100%, -50%) skew(0deg);
                        }

                        &:hover {
                            transition: background 0.15s 0.2s, box-shadow 0.15s 0.25s ease-out,
                                color 0.2s;
                            box-shadow: inset 0 0 0 2px transparent;

                            text-decoration: none;
                            outline-width: 0;

                            &:after {
                                transform: translate(-10%, -50%) skew(-30deg);
                                opacity: 1;
                            }
                        }
                    }
                }

                .smallDisclaimer {
                    font-size: 12px;
                    line-height: 16px;
                    color: $white-smoke-color;
                    margin: 16px 0 0 0;
                    text-align: center;

                    a {
                        font-size: 12px;
                        line-height: 16px;
                        color: $white-smoke-color;
                        text-decoration: underline;
                    }
                }

                .disclaimer {
                    padding: 8px;
                    background-color: $disabled-color;
                    border-radius: 5px;
                    opacity: 75%;
                    margin: 16px 0 0 0;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    max-width: 320px;

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: $slate-color;
                        margin: 0;
                    }
                }
            }
        }
    }
}
