$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.slider {
    display: inline-block;
    width: 100%;
    float: left;
    margin-top:16px;
    .sliderContainer {
        .heetsItem {
            display: flex !important;
            flex-flow: column;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            padding: 0 10px;
            max-width: 350px;
            min-width:300px;
            width: 300px !important;

            @media only screen and (max-width: 450px) {
                max-width: 220px;
                width: 100% !important;
            }

            .imgTag {
                z-index: 2000;
                top: -10px;
                height: 250px;
                left: 0;
                right: 0;
                border-radius: 0;
                margin: auto;
                -webkit-transition: top 0.7s ease-in-out;
                -moz-transition: top 0.7s ease-in-out;
                -o-transition: top 0.7s ease-in-out;
                transition: top 0.7s ease-in-out;
                transition-delay: 0s;
                -webkit-transition-delay: 0;
            }

            .imgTagInitial {
                z-index: 2000;
                top: 50px;
                height: 250px;
                -webkit-transition: top 0.7s ease-in-out;
                -moz-transition: top 0.7s ease-in-out;
                -o-transition: top 0.7s ease-in-out;
                transition: top 0.7s ease-in-out;
                transition-delay: 0s;
                -webkit-transition-delay: 0;
            }

            .imgContainer {
                position: relative;
                min-height: 230px;
                width: 202px;
                overflow: hidden;
                border-bottom-right-radius: 50% 45%;
                border-bottom-left-radius: 50% 45%;
                padding-top: 30px;
            }
            .imgTransparent {
                opacity: 0;
                z-index: 1100;
            }
            .imgOpacity {
                opacity: 0.8;
                z-index: 1100;
            }

            h3 {
                font-size: 16px;
                color: $slate-color;
                margin: 30px auto 0 auto;
                padding: 0 7px;
            }

            h4 {
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                color: $slate-color;
                line-height: 1;
                padding: 0 5px;
            }
            p {
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                color: $slate-color;
                margin: 20px auto 10px auto;
                line-height: 1;
                padding: 0 5px;
            }

            img {
                display: block;
                margin: 0 auto;
                width: 202px;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                border-radius: 100%;
                -webkit-transition: opacity 0.9s ease-in-out;
                -moz-transition: opacity 0.9s ease-in-out;
                -o-transition: opacity 0.9s ease-in-out;
                transition: opacity 0.9s ease-in-out;
                transition-delay: 0s;
                -webkit-transition-delay: 0;
            }
        }
    }

    :global(.slick-arrow) {
        position: absolute;
        z-index: 1;
        left: 25px;
        top: 35px;
        svg {
            path {
                stroke: $turquoise-color;
            }
        }

        &:global(.slick-prev) {
            transform: rotate(-180deg);
            left: 30px;
            top: 39px;

            @media only screen and (max-width: 850px) {
                left: 6%;
            }
        }

        &:global(.slick-next) {
            right: auto;
            left: 75px;
        }
    }



    :global(.slick-dots) {
        &:global(.slick-dots-bottom) {
            bottom: -16px !important;
            left: 0;
            right: 0;
            justify-content: center;
            margin: auto;
            top: auto !important;

            :global(.slick-active button) {
                background: $turquoise-color;
            }

            :global(li button) {
                background: $disabled-text-color;
            }
        }
    }

}
