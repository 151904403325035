$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {

    .slidersContainer{
        margin-top: 30px;
    }

    .memberContainer {
        display: flex;
        flex-direction: column;
        background: $turquoise-color;
        padding: 24px;
        margin-bottom: 50px;

        .profileImg {
            width: 100%;
        }

        .quotations {
            width: 25px;
            margin: 32px 0 16px;
        }

        .description {
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            text-align: left;
            min-height: 80px;
            margin: 0 0 40px;
            color: $slate-color;

            @media only screen and (max-width: 1315px) {
                min-height: 90px;
            }

            @media only screen and (max-width: 880px) {
                min-height: 110px;
            }

            @media only screen and (max-width: 850px) {
                min-height: 80px;
            }

            @media only screen and (max-width: 585px) {
                min-height: 110px;
            }

            @media only screen and (max-width: 420px) {
                margin: 0 0 20px;
            }

            @media only screen and (max-width: 360px) {
                min-height: 132px;
            }
        }

        .extraDetails {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            div {
                height: 32px;
            }

            .signature {
                object-fit: contain;
            }

            p {
                margin: 0;
                font-weight: bold;
                font-size: 12px;
                color: $slate-color;
            }
        }
    }

  .sectionWrapper {
    width: 99% !important;
      margin-top: -40px;
  }

    .sectionContainer {
        margin: 40px 0;
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        height: 630px;

        @media only screen and (min-width: 1241px) and (max-width: 1400px) {
            height: 690px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1000px) {
            height: 680px;
        }

        @media only screen and (max-width: 640px) {
            height: 670px;
        }

        @media only screen and (max-width: 500px) {
            height: 780px;
        }

        @media only screen and (max-width: 380px) {
            height: 800px;
        }

        @media only screen and (max-width: 350px) {
            height: 850px;
        }

        @media only screen and (max-width: 320px) {
            height: 810px;
        }

        h3 {
            font-size: 29px;
            font-weight: bold;
            line-height: 32px;
            margin: 0 0 32px;

            @media only screen and (max-width: 640px) {
                font-size: 24px;
                margin: 0 0 24px;
            }
        }

        .videoContainer{

            @media only screen and (max-width: 570px) {
                margin-bottom: 24px;
            }

            .videoPlayer {
                width: 100%;
                aspect-ratio: 16/9;
                padding: 0!important;

                @media only screen and (max-width: 570px) {
                    aspect-ratio: 1/1;
                }

                > div, iframe{
                    width: 100%!important;
                    aspect-ratio: 16/9;
                    padding: 0!important;

                    @media only screen and (max-width: 570px) {
                        aspect-ratio: 1/1;
                    }
                }
            }
        }

        .imageSection {
            height: 389px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 550px) {
                height: 290px;
            }

            @media only screen and (max-width: 450px) {
                height: 230px;
            }

            @media only screen and (max-width: 420px) {
                height: 215px;
            }

            @media only screen and (max-width: 370px) {
                height: 202px;
            }

            img {
                margin: auto auto;
                object-fit: contain;
                height: 320px;

                @media only screen and (max-width: 550px) {
                    height: 270px;
                }

                @media only screen and (max-width: 450px) {
                    height: 215px;
                }

                @media only screen and (max-width: 420px) {
                    height: 190px;
                }

                @media only screen and (max-width: 370px) {
                    height: 190px;
                }
            }
        }

        .description {
            text-align: left;
            min-height: 170px;
            margin: 20px 0 0;

            @media only screen and (max-width: 640px) {
                margin: 0;
            }
        }
    }

    :global(.slick-arrow) {

        svg {
            path {
                stroke: $turquoise-color;
            }
        }
    }

    :global(.slick-prev) {
        transform: rotate(180deg) translateY(-4px);
        position: absolute;
        right: 40px;
        top: -20px;
        left: auto;
    }

    :global(.slick-next) {
        position: absolute;
        top: -20px;
        right: 5px;
    }
}
