$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;

    width: 100%;
    max-width: 366px;
    height: fit-content;
    min-height: 134px;
    padding: 32px;
    background-color: $soft-white;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $slate-color;
        margin-bottom: 16px;
    }

    .extraText {
        text-align: center;
        max-width: 270px;
        font-size: 12px;
        line-height: 16px;
    }

    .rate {
        color: $accent-amber;
        font-size: 33px;

        li {
            width: 30px;
        }

        path {
            fill: $accent-amber;
        }

        :global(.ant-rate-star-zero) {
            :global(.ant-rate-star-second) {
                path {
                    fill: #c4c3c6;
                }
            }
        }

        :global(.ant-rate-star) {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    .descriptionContainer {
        .description {
            h3 {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                margin: 16px 0;
                font-weight: 400;
            }

            p {
                font-size: 12px;
                text-align: center;
                line-height: 16px;
                margin: 16px 0;
            }
        }

        button,
        a {
            display: block;
            margin: 16px auto;
        }
    }
}
