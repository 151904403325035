$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.noStyle {
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: none;
  outline: none;
  border: none;

  cursor: pointer;
  padding: 0;
  margin: 0;
}

:not(.noStyle) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.button {
    display: inline-block;
    background: $turquoise-color;
    -webkit-appearance: none !important; // IOS Fix
    position: relative;
    text-decoration: none;
    user-select: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    border: none;
    text-align: center;

    transition: background 0.2s, box-shadow 0.3s ease-out, color 0.2s;
    will-change: background, box-shadow, color;
    border-radius: 50px;
    padding: 13px 25px;
    overflow: hidden;
    z-index: 1;

    font-family: 'IQOS', 'Helvetica Neue', sans-serif; // For Storybook
    font-size: 14px;

    color: $slate-color;

    span {
      display: inline-block;
      width: 100%;
    }

    &:not(.text) {
      &:after {
        background: $turquoise-color;
        position: absolute;
        content: ' ';
        z-index: -1;
        opacity: 1;

        height: 120%;
        width: 120%;

        top: 50%;
        left: -1px;

        transition: transform 0.25s ease-out;
        transform: translate(-100%, -50%) skew(0deg);
      }
    }

    @media (hover: hover) {
      &:hover {
        transition: background 0.15s 0.2s, box-shadow 0.15s 0.25s ease-out, color 0.2s;
        box-shadow: inset 0 0 0 2px transparent;

        text-decoration: none;
        outline-width: 0;
        color: #1c1a20;

        &:after {
          transform: translate(-10%, -50%) skew(-30deg);
          opacity: 1;
        }
      }
    }

    svg {
      display: inline-block;
      float: right;
      margin: 0;
    }

    // Types
    &.solid {
      box-shadow: inset 0 0 0 2px $slate-color;
      background: $slate-color;
      color: $soft-white;

      &:active {
        background: $slate-color;
      }

      &.light {
        box-shadow: inset 0 0 0 2px $soft-white;
        background: $soft-white;
        color: $slate-color;

        &:hover {
          color: $slate-color;
        }
      }

      &.turquoise {
        box-shadow: inset 0 0 0 2px $turquoise-color;
        background: $turquoise-color;
        color: $slate-color;
      }
    }

    &.outlined {
      box-shadow: inset 0 0 0 2px $slate-color;
      color: $slate-color;
      background: none;

      &.light {
        box-shadow: inset 0 0 0 2px $soft-white;
        color: $soft-white;

        &:hover {
          color: $soft-white !important;
        }
      }

      &.turquoise {
        box-shadow: inset 0 0 0 2px $turquoise-color;
        color: $slate-color;
      }
    }

    &.text {
      border-radius: 0;
      background: none;
      padding: 0;

      border-bottom: 2px solid $turquoise-color;
      font-size: inherit;


      &.light {
        border-bottom: 2px solid $soft-white;
        color: $soft-white;
      }

      &.dark {
        border-bottom: 2px solid $slate-color;
        color: $slate-color;
      }

      &:hover {
        border-color: $turquoise-color;
      }
    }

    // Sizes
    &.small {
      font-size: 12px;
    }

    &.tiny {
      font-size: 9.5px;
      line-height: 9.5px;
    }

    // Hover variants
    &.dark--hover {
      &:not(.text) {
        &:hover {
          box-shadow: inset 0 0 0 2px $slate-color;
          color: $soft-white;
        }
      }

      &:after {
        background: $slate-color;
      }
    }

    &.light--hover {
      &:not(.text) {
        &:hover {
          box-shadow: inset 0 0 0 2px $soft-white;
          color: $slate-color;
          background: $soft-white;
        }
      }

      &.text {
        &:hover {
          border-color: $soft-white;
        }
      }

      &:after {
        background: $soft-white;
      }
    }

    &.turquoise--hover {
      &:not(.text) {
        &:hover {
          box-shadow: inset 0 0 0 2px $turquoise-color;
          color: $slate-color;

          &.dark {
            color: $soft-white !important;
          }
        }
      }

      &.text {
        &:hover {
          border-color: $turquoise-color;
        }
      }

      &:after {
        background: $turquoise-color;
      }
    }

    // States
    &.disabled {
      pointer-events: none;
      cursor: no-drop;

      box-shadow: inset 0 0 0 2px $disabled-color;
      background: $disabled-color;
      color: $disabled-text-color;

      &:after {
        display: none;
      }
    }

    &.loading {
      svg {
        margin: 0;
      }
    }

    &.large {
      font-size: 16px;
    }

    &.fullwidth {
      width: 100%;
    }

    &.inline {
      font-size: inherit;
    }
  }
}
