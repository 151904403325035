$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.cardWrapper {
    padding: 0 0 25px;

    .thumbnail {
        display: inline-block;
        object-fit: cover;
        height: 380px;
        width: 100%;
        float: left;
        padding: 0 0 15px;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        float: left;
        gap: 30px;
        text-align: left;
        margin: auto auto 10px;

        .title {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 10px;

            h3 {
                margin: 0 0 16px;
                font-size: 21px;
                line-height: 25px;
                color: #34303D;
            }
        }

        .extraInfo {
            width: 45%;

            small {
                color: #8c8a90 !important;
                display: inline-block;
                font-size: 14px;
                opacity: 0.7;
                margin-bottom: 16px;
                line-height: 20px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                color: #34303D;
            }
        }
    }

    .mobileContent {
        text-align: left;

        h3 {
            font-size: 21px;
            font-weight: 700;
            line-height: 25px;
            color: #34303D;

            margin: auto auto 10px;
        }

        small {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #8C8A90;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #34303D;

            margin: 15px auto 20px;

        }
    }
}
