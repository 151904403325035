$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    text-align: left;
    margin: 0;
    width: 100%;
    float: left;

    .content {
        display: block;
        max-width: calc(100% - 140px);
        margin: 0 auto;
        width: 90%;

        @media only screen and (max-width: 850px) {
            max-width: 100%;
            width: 100%;
        }

        h1 {
            line-height: 32px;
            font-size: 28px;
            color: $slate-color;
            margin: 0 0 24px;
        }

        h2 {
            line-height: 28px;
            font-size: 21px;
            color: $slate-color;
            margin: 0 0 24px;
        }
    }

    .slider {
        display: inline-block;
        width: 100%;
        float: left;

        .headerSlide {
            :global(.keen-arrows) {
                img {
                    width: 24px;
                    height: 14px;
                    min-width: auto;
                }
            }
        }

        img {
            min-width: 95%;
        }
        @media only screen and (max-width: 850px) {
            :global(.keen-arrows) {
                padding: 0 5% 20px 0 !important;
            }
        }
    }

    .image {
        display: inline-block;
        position: relative;
        width: 100%;
        float: left;

        @media only screen and (max-width: 850px) {
            margin: 0 0 0 -5.5%;
            width: 111%;
        }

        &.notFullWidth {
            padding: 20px 20px 0;
        }

        .playIconButton {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.cursorPointer {
            cursor: pointer;
        }

        img {
            display: inline-block;
            object-fit: cover;
            height: 375px;
            width: 100%;
            float: left;

            @media only screen and (max-width: 650px) {
                height: 249px;
            }

            &.customDimension {
                height: 420px;

                @media only screen and (min-width: 650px) {
                    height: 450px;
                }

                @media only screen and (min-width: 700px) {
                    height: 500px;
                }

                @media only screen and (min-width: 800px) {
                    height: 535px;
                }

                @media only screen and (min-width: 851px) {
                    height: 400px;
                }

                @media only screen and (min-width: 1000px) {
                    height: 410px;
                }

                @media only screen and (min-width: 1050px) {
                    height: 430px;
                }

                @media only screen and (min-width: 1110px) {
                    height: 470px;
                }

                @media only screen and (min-width: 1200px) {
                    height: 530px;
                }

                @media only screen and (min-width: 1241px) {
                    height: 400px;
                }

                @media only screen and (min-width: 1400px) {
                    height: 430px;
                }
            }
        }

        .badges {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;

            position: absolute;
            gap: 10px;

            left: 16px;
            top: 16px;

            div {
                margin: 0;
            }

            .badgeIcon {
                display: flex;
                align-items: center;
                justify-content: center;

                background: $soft-white;
                border-radius: 50px;
                padding: 3px;

                height: 42px;
                width: 42px;

                svg {
                    width: 100%;
                    float: left;
                }
            }

            .giftIcon {
                height: 42px;
                width: 42px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .videoPlayer {
            width: 119%;
            margin: 0 0 0 -9.5%;
            aspect-ratio: 16/9;

            @media only screen and (max-width: 460px) {
                aspect-ratio: 1/1;
                margin: 0;
                width: 100%;
            }

            > div,
            iframe {
                aspect-ratio: 16/9;
                padding: 0 !important;
                width: 100%;
                float: left;
                overflow: hidden;

                @media only screen and (max-width: 460px) {
                    aspect-ratio: 1/1;
                }
            }

            //iframe{
            //    object-fit: cover;
            //    min-height: 325px;
            //}
        }

        .isVideo {
            display: inline-block;
            position: relative;
            cursor: pointer;
            width: 100%;
            float: left;

            svg {
                position: absolute;
                left: calc((100% - 50px) / 2);
                top: calc((100% - 50px) / 2);
            }
        }

        .likeButton {
            border: 3px solid $white-smoke-color;
            background: $white-smoke-color;
            border-radius: 50px;
            position: absolute;
            cursor: pointer;

            bottom: -24px;
            right: 28px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 24px;
            height: 48px;
            width: 48px;

            &.liked {
                background: $turquoise-color;
                color: $slate-color;
            }

            &.sliderPosition {
                right: 58px;
            }

            &.whiteBorder {
                border: 3px solid $soft-white;
            }
        }
    }
}
