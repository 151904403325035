$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    .arrows {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0 20px 0;

        .arrowLeft,
        .arrowRight {
            user-select: none;
            cursor: pointer;
        }

        .arrowLeft {
            margin-right: 20px;
            transform: rotate(180deg);
        }

        .arrowDisabled {
            cursor: default;
            opacity: 0.3;
        }
    }

    .bottomArrows {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .arrowLeft,
        .arrowRight {
            user-select: none;
            cursor: pointer;
        }

        .arrowLeft {
            margin-right: 20px;
            transform: rotate(180deg);
        }

        .arrowDisabled {
            cursor: default;
            opacity: 0.3;
        }
    }

    .dots {
        margin: 25px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
            cursor: pointer;
            outline: none;
            border: none;
        }

        .dot {
            margin: 0 8px;
            position: relative;
            display: block;
            width: 12px;
            height: 3px;
            padding: 0;
            color: transparent;
            font-size: 0;
            background: #00d1d2;
            border: 0;
            border-radius: 1px;
            outline: none;
            cursor: pointer;
            opacity: 0.3;
            transition: all 0.5s;

            &.active {
                width: 24px;
                opacity: 1;
            }
        }
    }
}

.slide {
    cursor: pointer;
}

.tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    :global(.ant-tabs) {
        width: 100%;

        :global(.ant-tabs-nav) {
            border-radius: 100px;
            padding: 4px;
            background-color: #e4e4e4;
            width: 90%;
            margin: 25px auto;

            &::before {
                border: none;
            }

            :global(.ant-tabs-nav-list) {
                width: 100%;

                :global(.ant-tabs-tab) {
                    width: 33.3%;
                    border-radius: 82px;
                    background-color: #e4e4e4;
                    justify-content: center;
                    border: none;
                }

                :global(.ant-tabs-tab:hover) {
                    color: $slate-color;
                    font-size: 14px;
                    line-height: 18px;
                }

                :global(.ant-tabs-tab-active) {
                    background-color: #fffdfb;
                }

                :global(.ant-tabs-tab-btn) {
                    color: $slate-color;
                    text-shadow: none;
                    font-size: 14px;
                    line-height: 18px;

                    @media only screen and (max-width: 470px) {
                        font-size: 12px;
                    }

                    @media only screen and (max-width: 380px) {
                        font-size: 10px;
                    }
                }
            }
        }

        :global(.ant-tabs-content-holder) {
            width: 100%;
            margin: 24px 0 0 0;
        }
    }
}
