$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.container {
  position: absolute;
  width: 120px;
  height: 100px;
  user-select: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  opacity: 1;


  .images {
    position: relative;

    img {
      border-radius: 88px;
      // border: 2px solid $turquoise-color;
      padding: 3px;
      width: 88px;
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      animation: fadeIn 0.5s ease-in;
    }
  }

  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  &.fadeOut {
    animation: fadeOut 1s ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
}

.square {
  position: absolute;
  height: 100px;
  user-select: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  opacity: 1;
  width: fit-content;

  .images {
    position: relative;

    img {
      width: 100%;
      max-width: 185px;
      border-radius: 20px;
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      animation: fadeIn 0.5s ease-in;
    }
  }

  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  &.fadeOut {
    animation: fadeOut 1s ease-out;
    animation-delay: 0s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
