$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
  .cleaningStepsTitle {
    margin-top: 70px !important;

    @media only screen and (max-width: 850px) {
      margin-top: 50px !important;
    }
  }

  li {
    color: #34303D;
    line-height: 20px;
  }

  .stepDisclaimer {
    font-size: 12px;
    line-height: 16px;
  }

  .benefitsTitle {
    margin-top: 50px !important;
  }

  .stepsImage {
    img {
      margin-bottom: -24px;

      @media only screen and (max-width: 750px) {
        margin:0 0 24px;
      }
    }
  }

  .tipsContainer{
    border-radius: 4px;
    background: #99EBEB;
    padding: 15px 5px;

    @media only screen and (max-width: 850px) {
      padding: 16px 25px;
    }
  }

  .capsCollection {
    display: block;
    margin: 24px 0 0;
    font-size: 14px;
    color: #34303D;
    text-decoration: underline;
    text-decoration-color: #00d1d2;
    text-underline-offset: 5px;
  }

  .videoContainer {
    display: inline-block;
    text-align: left;
    margin: 25px 0;
    max-width: calc(100% - 50px);
    width: 90%;

    h3 {
      margin-bottom: 25px;
    }

    img {
      border-radius: 4px;
      margin:0 0 -24px;
    }
  }

  .congratsContainer {
    background-color: $turquoise-color;
    display: inline-block;
    width: 100%;
    float: left;

    border-radius: 4px;
    text-align: left;
    padding: 24px;
    margin: 24px auto;

    @media only screen and (max-width: 750px) {
        max-width: 100%;
        width: 100%;
    }

    h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
    }

    .reward {
        display: inline-block;
        background: $soft-white;
        padding: 4px 10px 4px 4px;
        border-radius: 50px;
        margin: 24px 0 0;
        left: 25px;
        top: 25px;

        svg {
            float: left;
        }

        span {
            display: inline-block;
            margin: 2px 0 0 10px;

            line-height: 14px;
            font-size: 12px;

            b {
                font-size: 14px;
            }
        }
    }
  }
}

.videoModal {

  .modalContent {
    padding: 0;

    .rating {
      &.padding {
        padding: 80px;
      }
    }

    .player {
      position: relative;
      z-index: 2;
    }
  }

}