$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {
    display: inline-block;
    background-color: $white-smoke-color;
    text-align: left;
    padding: 36px 24px;
    width: 100%;
    float: left;

    &.hideWrapper {
        display: none !important;
    }

    @media only screen and (max-width: 750px) {
        margin: 0 0 0 -5.5%;
        width: 110%;
    }

    .completed {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        h3 {
            line-height: 24px;
            font-weight: 700;
            font-size: 24px;

            color: $slate-color;
            margin: 0 0 16px;
        }

        p {
            font-size: 16px;
        }

        .reward {
            display: inline-block;
            background: $soft-white;
            padding: 4px 10px 4px 4px;
            border-radius: 50px;
            margin: 0 0 24px;
            left: 25px;
            top: 25px;

            svg {
                float: left;
            }

            span {
                display: inline-block;
                margin: 2px 0 0 10px;

                line-height: 14px;
                font-size: 12px;

                b {
                    font-size: 14px;
                }
            }
        }

        .title {
            font-size: 20px;
        }

        .description {
            margin: 0;

            @media screen and (max-width: 870px) {
                margin: 0;
            }
        }

        .poll {
            display: inline-block;
            margin: 8px auto 0;
            width: 100%;
            float: left;

            @media screen and (max-width: 640px) {
                margin: 0;
            }

            h4 {
                text-align: left;
                font-size: 18px;
            }

            .otherResponses {
                text-transform: none;
            }

            .result {
                display: inline-block;
                position: relative;
                width: 100%;
                float: left;

                &:not(:last-of-type) {
                    margin: 0 0 8px;
                }

                .mostVoted {
                    font-weight: bold;
                }

                .name {
                    position: absolute;
                    z-index: 1;
                    left: 8px;
                    top: 65%;

                    text-align: left;
                    font-size: 16px;
                    line-height: 1;

                    @media screen and (max-width: 640px) {
                        max-width: 250px;
                    }
                }

                :global(.ant-progress) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    height: 50px;

                    @media screen and (max-width: 640px) {
                        width: 100%;
                    }
                }

                :global(.ant-progress-inner) {
                    background: $soft-white;
                    border-radius: 10px;
                    height: 50px;
                }

                :global(.ant-progress-text) {
                    position: absolute;
                    top: 78%;
                    right: 16px;
                    font-weight: bold;
                }

                :global(.ant-progress-outer) {
                    width: 100% !important;
                    padding: 0;
                    margin: 0;
                }

                :global(.ant-progress-bg) {
                    height: 50px !important;
                    border-radius: 10px;
                    float: left;
                }
            }
        }
    }

    .title {
        line-height: 22px;
        font-weight: bold;
        font-size: 20px;
        text-align: left;
        margin: 0 0 24px 0;
        color: $slate-color;

        @media only screen and (max-width: 870px) {
            margin: 0 0 16px 0;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;

        .description {
            padding: 0 ;
            line-height: 18px;
            font-size: 14px;
            margin: 0;

            color: $slate-color;
            text-align: left;
        }

        @media screen and (max-width: 750px) {
            flex-direction: column;

            .description {
                padding: 0;
            }
        }

        .badgesContainer {
            .FpIcon {
                margin-bottom: 16px;

                @media only screen and (min-width: 870px) {
                    margin: 0 0 24px 0;
                }
            }
        }
    }

    .customDetails {
        flex-direction: column;

        .description {
            @media screen and (min-width: 750px) {
                padding: 0 0 15px 0;
            }

            p {
                a {
                    color: $slate-color;
                    text-decoration: underline;
                }
            }
        }

        .badgesContainer {
            display: flex;
            max-width: 100%;
            gap: 8px;

            @media screen and (max-width: 850px) {
                margin-bottom: 12px;
            }

            @media only screen and (max-width: 375px) {
                flex-direction: column;

                div:first-child {
                    max-width: 140px;
                    margin: 0 0 6px 0;
                }

                .badge {
                    margin-bottom: 12px;
                }
            }

            .reward {
                background-color: red;
                margin: 0;
            }

            .badge {
                display: flex;

                img {
                    width: 44px;
                    height: 41px;
                    margin-right: 5px;
                    cursor: pointer;
                }

                p {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .submitButton {
        display: block !important;
        margin: auto;
        float: left;

        @media only screen and (max-width: 460px) {
            width: 100%;
        }
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        @media only screen and (min-width: 1241px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .submitButton {
            @media only screen and (min-width: 1241px) {
                margin: 0;
            }
        }

        .badgesButton {
            max-width: 160px;
            line-height: 22px;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 32px;

        :global(.ant-radio-wrapper) {
            border: none !important;
            border-radius: 10px !important;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 16px 8px;
            background: $soft-white;
        }
    }
}

.congratsContainer {
    background-color: $turquoise-color;
    display: inline-block;
    width: 100%;
    float: left;

    border-radius: 4px;
    text-align: left;
    padding: 24px;
    margin: 24px auto;

    @media only screen and (max-width: 750px) {
        max-width: 100%;
        width: 100%;
    }

    h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
    }

    .completed {
        &.customCongrats {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                margin-bottom: 24px;
            }

            p {
                text-align: center;
                margin: 0;

                a {
                    cursor: pointer;
                    color: $slate-color;
                    text-decoration: underline;
                }
            }
        }

        .reward {
            display: inline-block;
            background: $soft-white;
            padding: 4px 10px 4px 4px;
            border-radius: 50px;
            margin: 24px 0 0;
            left: 25px;
            top: 25px;

            svg {
                float: left;
            }

            span {
                display: inline-block;
                margin: 2px 0 0 10px;

                line-height: 14px;
                font-size: 12px;

                b {
                    font-size: 14px;
                }
            }
        }
    }
}
