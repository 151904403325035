$slate-tint: #8c8a90;
$slate-color: #34303d;
$turquoise-color: #00d1d2;
$turquoise-color-opacity: #00d1d240;
$purple-color: #44135d;
$pink-color: #943451;

$soft-white: #fffdfb;
$white-smoke-color: #f6f4f0;

$disabled-color: #e4e4e4;
$disabled-text-color: #c4c3c6;

$collapse-content-text-color: #0c0b0e;

$medium-dark-grey: #716e76;
$dark-grey-border: #504d59;
$light-grey-border: #d9d9d9;

$dark-title: #0c0b0e;

// Tier colors
$silver-color: #8c8a90;
$gold-color: #e3803b;
$platinum-color: #4c4c67;

// Accents
$accent-red: #db3624;
$accent-deep-red: #922C20;
$accent-blue: #287baf;
$accent-amber: #e3813b;
$accent-yellow: #ffd657;
$accent-sky-blue: #6ba9db;

// Disclaimers
$red-disclaimer: #db362440;
$yellow-disclaimer: #ffd65740;
$turquoise-disclaimer: #00d1d240;
$white-smoke-disclaimer: #F6F4F080;

.wrapper {

  .title {
    margin: 0;
    h3 {
      font-size: 29px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .sliderWrapper {
    width: 100%;
    background-color: $white-smoke-color;
    padding: 20px 0 0;

    @media only screen and (max-width: 600px) {
      margin: 0 -5.5% 0;
      width: 111%;
    }

    :global(.keen-arrows) {
      padding: 0 20px 20px 0;
    }

    .slide {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 0 32px 32px;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 24px;
      @media only screen and (max-width: 600px) {
        padding: 0 20px 20px;
      }

      .title {
        margin: 0 0 24px;
        text-align: left;
        font-size: 21px;
        font-weight: 700;
        line-height: 120%;
        color: $slate-color;
      }

      a {
        color: $slate-color;
        border-bottom: 1px solid $slate-color;
        @media only screen and (max-width: 600px) {
          line-height: 25px;
        }
      }
      .quizCard {
        // margin: 0 0 24px;
        background-color: $turquoise-color;
        border-radius: 8px;
        padding: 24px;
        .container {
          width: 100%;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 24px;

          @media only screen and (max-width: 600px) {
            flex-direction: row;
          }

          .visual {
            width: 100%;
            position: relative;
            .rewardBadge {
              text-align: left;
              @media only screen and (max-width: 400px) {
                span {font-size: 10px;}
              }
            }
            .seenIcon {
              position: absolute;
              top: 5px;
              left: 5px;
              z-index: 1;
              > svg {
                stroke: $soft-white;
              }
            }
            img {
              max-width: 100%;
            }
          }
          .content {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            h3 {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              color: $slate-color;
            }
            p {
              margin: 16px 0;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: $slate-color;
            }
            button {
              width: fit-content;
            }
          }
        }
      }
    }
  }

  .fullWidthContent {
    margin: 24px 0 0;

    @media only screen and (min-width: 1024px) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }


  .steps {
    margin: 0 0 16px;
    
    h4 > span { color: $turquoise-color; }

    .step {
      margin-top: 16px;
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;

      @media only screen and (max-width: 600px) {
        gap: 8px;
      }

      .icon {
        margin-top: 5px;
      }
    }
  }
}